.project-panel {
  font-size: 34px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: 1.5px;
  color: var(--text-color);
  margin-top: 50px;
}

img.diinco-project-banner {
  height: 400px;
  background-color: var(--bg-color);
  object-fit: cover;
}

.divider-1 {
  width: 100%;
  margin-top: 10px;
  margin-bottom: 20px;
  border-bottom: solid 3px var(--secondary-color);
}

.divider-2 {
  width: 100%;
  margin-top: 10px;
  margin-bottom: 20px;
  border-bottom: solid 3px var(--primary-color);
}

.banner-relative {
  position: relative;
  width: 100%;
  margin-bottom: 20px;
  background-color: #535556;
}

.project-name-absolute {
  font-size: 32px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.37;
  letter-spacing: 1.8px;
  text-align: right;
  color: #fff;

  padding-right: 20px;
  position: absolute;
  bottom: 15px;
  right: 0px;
  /* background-color: red; */
  width: 95%;
}

.uppercase {
  text-transform: uppercase;
}

.description-container {
  color: var(--text-gray-color);
  margin-top: 30px;
}

.uk-switcher li {
  max-height: 80vh;
  overflow-y: auto;
}

.dc-project-title {
  max-width: 65%;
}

.dc-max-height {
  max-height: 100px;
}

.leo-project-title {
  text-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  font-family: OpenSans;
  font-size: 40px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.36;
  letter-spacing: 1.5px;
  text-align: right;
  color: #f9b74e;
}

.leo-badge.active {
  border-radius: 50%;
  background-color: var(--card-color-yellow) !important;
  padding: 1px 1px;
  border: 3px solid black !important;
  filter: none !important;
  margin: 0px 0px !important;
}

.leo-badge {
  height: 65px;
  width: 65px;
  border-radius: 50%;
  background-color: transparent;
  padding: 1px 1px;
  border: 3px solid black;
  margin: 10px 10px;
}

.leo-badge img {
  width: 100%;
  height: 100%;
}

.leo-badge-container.active {
  border-radius: 50%;
  border: 6px solid var(--card-color-yellow);
  margin-bottom: 6px;
}

.leo-text-area {
  border: solid 1px #f9b74e !important;
  border-radius: 5px !important;
  padding: 15px !important;
  background-color: transparent !important;
  color: var(--text-color) !important;
}
