.dc-error-container {
  height: calc(100vh - 150px) !important;
  text-align: center;
}

.dc-error-img {
  margin-bottom: 40px;
}

.dc-error-text {
  color: var(--primary-color-alt);
  font-size: 30px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.22;
  letter-spacing: 2.07px;
}
