.fofo-accordion-title {
  color: var(--bg-dark-gray) !important;
  font-weight: 600;
}

.fofo-more-benefits {
  height: min-content;
  margin-left: 20px;
  text-decoration: underline;
}

.fofo-plan-container {
  margin-bottom: 30px;
}

.uk-accordion li.uk-open .uk-accordion-title:before {
  margin: 15px;
  /* content: url('data:image/svg+xml;base64,PHN2ZyBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGZpbGwtcnVsZT0iZXZlbm9kZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIgc3Ryb2tlLW1pdGVybGltaXQ9IjIiIHZpZXdCb3g9IjAgMCAyNCAyNCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJtMjEgNC4wMDljMC0uNDc4LS4zNzktMS0xLTFoLTE2Yy0uNjIgMC0xIC41MTktMSAxdjE2YzAgLjYyMS41MiAxIDEgMWgxNmMuNDc4IDAgMS0uMzc5IDEtMXptLTE2LjUuNWgxNXYxNWgtMTV6bTIuNDQ5IDcuODgyIDMuODUxIDMuNDNjLjE0Mi4xMjguMzIxLjE5LjQ5OS4xOS4yMDIgMCAuNDA1LS4wODEuNTUyLS4yNDJsNS45NTMtNi41MDljLjEzMS0uMTQzLjE5Ni0uMzIzLjE5Ni0uNTAyIDAtLjQxLS4zMzEtLjc0Ny0uNzQ4LS43NDctLjIwNCAwLS40MDUuMDgyLS41NTQuMjQzbC01LjQ1MyA1Ljk2Mi0zLjI5OC0yLjkzOGMtLjE0NC0uMTI3LS4zMjEtLjE5LS40OTktLjE5LS40MTUgMC0uNzQ4LjMzNS0uNzQ4Ljc0NiAwIC4yMDUuMDg0LjQwOS4yNDkuNTU3eiIgZmlsbC1ydWxlPSJub256ZXJvIi8+PC9zdmc+'); */
}

.uk-accordion li .uk-accordion-title:before {
  margin: 15px;
  /* content: url('data:image/svg+xml;base64,PHN2ZyBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGZpbGwtcnVsZT0iZXZlbm9kZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIgc3Ryb2tlLW1pdGVybGltaXQ9IjIiIHZpZXdCb3g9IjAgMCAyNCAyNCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJtMTEuOTk4IDIxLjk5NWM1LjUxNyAwIDkuOTk3LTQuNDggOS45OTctOS45OTcgMC01LjUxOC00LjQ4LTkuOTk4LTkuOTk3LTkuOTk4LTUuNTE4IDAtOS45OTggNC40OC05Ljk5OCA5Ljk5OCAwIDUuNTE3IDQuNDggOS45OTcgOS45OTggOS45OTd6bTAtMS41Yy00LjY5IDAtOC40OTgtMy44MDctOC40OTgtOC40OTdzMy44MDgtOC40OTggOC40OTgtOC40OTggOC40OTcgMy44MDggOC40OTcgOC40OTgtMy44MDcgOC40OTctOC40OTcgOC40OTd6bTQuODQ1LTYuNzExYy4xMDguMTQxLjE1Ny4zLjE1Ny40NTYgMCAuMzg5LS4zMDYuNzU1LS43NDkuNzU1aC04LjUwMWMtLjQ0NSAwLS43NS0uMzY3LS43NS0uNzU1IDAtLjE1Ny4wNS0uMzE2LjE1OS0uNDU3IDEuMjAzLTEuNTU0IDMuMjUyLTQuMTk5IDQuMjU4LTUuNDk4LjE0Mi0uMTg0LjM2LS4yOS41OTItLjI5LjIzIDAgLjQ0OS4xMDcuNTkxLjI5MXptLTcuNTY0LS4yODloNS40NDZsLTIuNzE4LTMuNTIyeiIgZmlsbC1ydWxlPSJub256ZXJvIi8+PC9zdmc+'); */
}

.modalAccept {
  background-color: #f9b74e;
  min-width: 200px;
  border-radius: 5px;
  padding: 35px 20px 0px 20px;
}

.leo-tag-sin-borde {
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: none;
  height: 90px !important;
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.leo-tag-sin-borde .dc-tags-input-header {
  margin-left: 0px;
  color: var(--placeholder-color) !important;
  opacity: 0.5;
}

.leo-tag-sin-borde .uk-flex.uk-flex-wrap {
  margin-left: -20px !important;
}
