.album-card-container {
  padding-right: 40px;
  position: relative;
}

.album-card-cover {
  height: 255px;
  position: relative;
  cursor: pointer;
}

.album-card-cover img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 5px;
}

.album-cover-title {
  position: absolute;
  bottom: 25px;
  left: 0px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  word-wrap: break-word;
  width: 100%;
  color: var(--card-color-yellow);
  text-transform: uppercase;
  z-index: 101;

  font-family: OpenSans;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  padding-left: 10px;
  box-sizing: border-box;
  text-align: left;
}

.album-cover-gradient {
  background-image: linear-gradient(
    to bottom,
    rgba(38, 38, 38, 0),
    var(--bg-dark-gray)
  );
  height: 127.5px;
  width: 100%;
  position: absolute;
  bottom: 0px;
  z-index: 100;
}

.album-cover-tags {
  position: absolute;
  bottom: 10px;
  left: 0px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  word-wrap: break-word;
  width: 100%;
  color: white;
  z-index: 101;
  padding-left: 10px;
  box-sizing: border-box;

  font-family: OpenSans;
  font-size: 8px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
  text-align: left;
}

.album-buttons-container {
  display: flex;
  flex-direction: column;
  width: 28px;
  position: absolute;
  right: 0px;
  top: 0px;
  gap: 10px;
}

.album-description {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  /* number of lines to show */
  line-clamp: 4;
  -webkit-box-orient: vertical;
}

.album-professionals {
  position: absolute;
  right: 0px;
  top: 20px;
  height: 50px;
  min-width: 100px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  background-color: var(--card-color-yellow);
  padding: 5px 10px 5px 8px;
  box-sizing: border-box;

  display: flex;
  align-items: center;
  gap: 5px;
}

.album-professionals .professional-name {
  font-family: OpenSans;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 16px;
  letter-spacing: normal;
  color: #262626;

  word-break: keep-all;
  height: 2.66;
  max-width: 100px;
}

.toggle-switch.album input[type='checkbox']:checked + .switch {
  background-color: var(--secondary-color);
}

.toggle-switch.album input[type='checkbox']:checked + .switch::before {
  transform: translate(23px, -2px);
  background-color: var(--secondary-color);
  border: solid black 2px;
}

[data-theme='light']
  .toggle-switch.album
  input[type='checkbox']:checked
  + .switch::before {
  border: solid white 2px;
}
