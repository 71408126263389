div.notification {
  background: rgba(141, 139, 139, 0.7);
  background-size: 400% 400%;
  color: var(--text-white-color);
  padding: 25px 15px 0px 20px;
  position: relative;
  min-height: 50px;
  display: flex;
  text-align: start;
  font-size: 1.2rem;
}

div.notification span {
  position: absolute;
  right: 10px;
  top: 10px;
  cursor: pointer;
}

div.notification.success::after {
  content: '';
  background: rgba(39, 197, 39, 0.479);
  position: absolute;
  top: 0px;
  width: 8px;
  left: 0px;
  height: 100%;
}
div.notification.error::after {
  content: '';
  background: rgba(197, 39, 39, 0.479);
  position: absolute;
  top: 0px;
  width: 8px;
  left: 0px;
  height: 100%;
}

@keyframes snack-animation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
