.dc-project-form-title {
  color: var(--text-white-color);
  font-size: 50px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.36;
  letter-spacing: 1.5px;
}

.dc-dark-input {
  background-color: var(--bg-color);
}

.diinco-project-form-dropzone-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-width: 2px;
  border-radius: 2px;
  border-color: #707070;
  border-style: solid;
  background-color: transparent;
  color: #cbcbcb;
  outline: none;
  height: 200px;
  cursor: pointer;
}

.diinco-project-form-dropzone-image {
  width: 100% !important;
  height: 100% !important;
  object-fit: cover;
}

.diinco-thumbs-container-project-form {
  width: 100%;
  height: 100px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  column-gap: 10px;
  overflow-x: auto;
  overflow-y: hidden;
  margin-bottom: 10px;
  white-space: nowrap;
}

.diinco-thumbs-style-project-form {
  width: 70px !important;
  height: 70px !important;
  aspect-ratio: 1 !important;
  object-fit: cover;
  position: relative;
}

.diinco-thumbs-delete-project-form {
  position: absolute;
  height: 20px;
  width: 20px;
  cursor: pointer;
  top: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  background-color: wheat;
}

.oski-add-button {
  color: #cbcbcb;
  background-color: var(--background-button);
  border-radius: 50%;
  padding: 5px;
}

.oski-title-modal {
  font-family: OpenSans;
  font-size: 30px;
  font-weight: bold;
  color: var(--secondary-color);
}

.customModalPhase {
  background-color: rgba(var(--bg-color-rgb), 0.9);
  border-radius: 5px;
}

.oski-button-phase {
  border: none;
  border-bottom: 2px solid var(--hover-color-alt);
  margin-bottom: 33px;
  text-transform: inherit;
  color: var(--placeholder-color) / 2;
  /* opacity: 0.5; */
  font-size: 16px;
  width: 25%;
  padding-left: 0px;
  text-align: left;
}

.oski-button-phase:hover {
  border-bottom: 2px solid var(--hover-color-alt);
  color: var(--placeholder-color) / 2;
}

.oski-button-phase:active {
  color: var(--secondary-color);
  border-bottom: 2px solid var(--secondary-color);
}

.oski-phase-title {
  font-family: OpenSans;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 0.45px;
  text-align: left;
  text-transform: uppercase;
  color: var(--switch-title-color);
}

.oski-phase-subtitle {
  font-family: OpenSans;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 0.45px;
  text-align: left;
  color: var(--text-white-color);
  min-width: 400px;
}

.oski-button-close {
  background-color: var(--bg-button-gray);
  border-radius: 50%;
  color: var(--bg-color);
  padding: 1px;
}

.oski-phase-object:hover {
  cursor: pointer;
}

.leo-error-message {
  font-size: 12px;
  color: var(--red-color);
  margin-top: 10px;
}

.leo-error-message span {
  margin-right: 3px;
}
