.oski-report-title {
  font-family: OpenSans;
  font-size: 20px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.35;
  letter-spacing: 0.6px;
  color: var(--text-color);
}

.oski-report-date {
  font-family: OpenSans;
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.35;
  letter-spacing: 0.6px;
  margin-top: 15px;
  color: var(--text-date-color);
}

.oski-report-stage {
  font-family: OpenSans;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.35;
  letter-spacing: 0.6px;
  margin-top: 10px;
  color: var(--text-color);
}

.oski-report-description {
  font-family: OpenSans;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 0.45px;
  margin-top: 25px;
  margin-bottom: 70px;
  color: var(--text-color);
  text-align: justify;
}

.oski-margin-bottom {
  margin-bottom: 50px !important;
}

.oski-text-orange {
  color: var(--secondary-color);
  font-family: OpenSans;
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.35;
  letter-spacing: 0.6px;
  margin-top: 50px;
}

.oski-padding-left {
  padding-left: 25%;
  margin-bottom: 20px;
}

.oski-doc-style {
  font-family: OpenSans;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.35;
  letter-spacing: 0.5px;
  color: var(--text-date-color);
  margin-left: 15px;
  text-transform: capitalize;
  text-decoration: none;
}
