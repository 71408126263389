.cj-text-component {
  font-family: 'Open Sans', sans-serif !important;
  /* margin: 0px 0px 0.35em; */
  white-space: pre-line;
  margin: 0px;
  padding: 0px;
}

.cj-text-component-margin {
  margin-bottom: 0.35em;
}

/* .cj-text-component-title{
    font-size: 30px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;

    letter-spacing: 0.9px;
    line-height: 41px;
}

.cj-text-component-subtitle{
    font-size: 20px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;

    letter-spacing: 0.6px;
    line-height: 27px;
}

.cj-text-component-body{
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;

    letter-spacing: 0.45px;
    line-height: 20px;
}

.cj-text-component-body2{
    font-size: 12px;
} */

.cj-text-component-h1 {
  font-weight: 300;
  font-size: 6rem;
  line-height: 1.167;
  letter-spacing: -0.01562em;
}

.cj-text-component-h2 {
  font-weight: 300;
  font-size: 3.75rem;
  line-height: 1.2;
  letter-spacing: -0.00833em;
}

.cj-text-component-h3 {
  font-weight: 400;
  font-size: 3rem;
  line-height: 1.167;
  letter-spacing: 0em;
}

.cj-text-component-h4 {
  font-weight: 400;
  font-size: 2.125rem;
  line-height: 1.235;
  letter-spacing: 0.00735em;
}

.cj-text-component-h5 {
  font-weight: 400;
  font-size: 1.5rem;
  line-height: 1.334;
  letter-spacing: 0em;
}

.cj-text-component-h6 {
  font-weight: 400;
  font-size: 1.25rem;
  line-height: 1.6;
  letter-spacing: 0.0075em;
}

.cj-text-component-subtitle {
  font-weight: 500;
  font-size: 1.1rem;
  line-height: 1.75;
  letter-spacing: 0.00938em;
}

.cj-text-component-subtitle2 {
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.57;
  letter-spacing: 0.00714em;
}

.cj-text-component-body {
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5;
  letter-spacing: 0.00938em;
}

.cj-text-component-body2 {
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1.43;
  letter-spacing: 0.01071em;
}

.cj-text-component-primary {
  color: var(--text-color);
}

.cj-text-component-secondary {
  color: var(--secondary-color);
}

.cj-text-component-inverted {
  color: var(--text-white-color);
}

.cj-text-component-inherit {
  color: inherit;
}
