.dc-router {
  min-height: calc(100vh - var(--footer-height));
}

.dc-footer-pin {
  position: relative;
  left: 0;
  bottom: 0;
  z-index: 11;
}

.page-wrapper {
  min-height: 100%;
  /* equal to footer height */
  margin-bottom: -70px;
}

.page-wrapper:after {
  content: '';
  display: block;
}

.diinco-footer-container,
.page-wrapper:after {
  height: 70px;
}

.floating-button {
  width: 60px;
  height: 40px;
  padding-right: 10px;
  background-color: var(--secondary-color);
  position: fixed;
  bottom: 50%;
  border-bottom-left-radius: 10px;
  border-top-left-radius: 10px;
  right: 0px;
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  cursor: pointer;
  box-shadow: inset 0 -3em 3em rgba(0, 0, 0, 0),
    0.1em 0.3em 1em rgba(0, 0, 0, 0.3);
}
.floating-button:hover {
  background-color: var(--primary-color);
}

.floating-button img {
  height: 70%;
  align-self: center;
}

/* modal style */
.customModal {
  background-color: var(--bg-dark-color) !important;
  color: var(--text-gray-color);
  padding: 30px !important;
  border-radius: 5px;
  min-width: 30%;
}

.modal-title {
  color: var(--text-gray-color);
}

.send-bug {
  display: flex;
  justify-content: center;
  margin-top: 25px;
  margin-bottom: 15px;
  position: relative;
}

.diinco-root-dropzone-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-width: 2px;
  border-radius: 2px;
  border-color: #707070;
  border-style: solid;
  background-color: transparent;
  color: #cbcbcb;
  outline: none;
  height: 200px;
  cursor: pointer;
}

.diinco-root-dropzone-image {
  width: 100% !important;
  height: 100% !important;
  object-fit: cover;
}

.diinco-thumbs-container-root {
  width: 100%;
  height: 100px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  column-gap: 10px;
  overflow-x: auto;
  overflow-y: hidden;
  margin-bottom: 10px;
  white-space: nowrap;
}
.diinco-thumbs-style-root {
  width: 70px !important;
  height: 70px !important;
  aspect-ratio: 1 !important;
  border-radius: 100%;
  object-fit: cover;
  position: relative;
}
.diinco-thumbs-delete-root {
  position: absolute;
  height: 20px;
  width: 20px;
  cursor: pointer;
  top: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  background-color: wheat;
}
