.fofo-bar-container {
  border-color: var(--secondary-color);
  border-style: solid;
  border-width: 1.5px;
  height: 10px;
  border-radius: 3px;
}

.fofo-bar-container .fofo-bar-values .value {
  float: left;
  text-align: center;
}

.fofo-bar-container .fofo-bars .fofo-bar {
  float: left;
  height: 10px;
}

.fofo-bar-container .fofo-bars div.fofo-bar:first-of-type {
  border-top-left-radius: 1px;
  border-bottom-left-radius: 1px;
}

.fofo-bar-container .fofo-bars div.fofo-bar:last-of-type {
  border-top-right-radius: 1px;
  border-bottom-right-radius: 1px;
}
