.dc-position-relative {
  position: relative;
}

.position-absolute-profile-img {
  position: absolute;
  top: -140%;
  right: -30%;
}

.oski-empty-profile {
  display: flex;
  border: 1px solid var(--text-white-color);
  width: 100%;
  height: 400px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.profile-name {
  font-weight: 530px;
  font-size: 40px;
}

.badges-name {
  color: var(--text-gray-color);
}

.dc-padding-left-small {
  margin-left: 30px;
}

.dc-profile-img {
  width: 200px;
  height: 200px;
  object-fit: cover;
  background-color: var(--bg-color);
}

.swiper-container > img {
  width: 100%;
  height: auto;
  object-fit: cover;
}
.diinco-professional-project-public-container {
  object-fit: cover;
  aspect-ratio: 1;
  padding: 3px;
}
div .diinco-little-project-container {
  display: flex;
  flex-direction: row;
  overflow-x: hidden;
  overflow-y: auto;
  justify-content: flex-start;
  align-content: flex-start;
}
div.diinco-little-project-container img {
  height: 70px;
  width: 70px;
  cursor: pointer;
  aspect-ratio: 1;
  object-fit: cover;
  padding: 0px 4px;
}
div.diinco-project-main-container-profile {
  margin-bottom: 50px;
}
h5.diinco-little-project-container {
  color: var(--text-white-color);
  text-transform: uppercase;
  font-size: 0.8rem;
  padding: 0px;
  margin: 0px;
  margin-bottom: 5px;
}

.oski-orange-link {
  color: var(--primary-color-alt) !important;
  font-size: 15px !important;
  text-transform: capitalize !important;
  text-decoration: underline;
  cursor: pointer;
  text-align: center;
  margin-top: 15px;
}

.oski-max-height {
  max-height: 800px;
  overflow-y: scroll;
}

.oski-masonry {
  width: 50%;
  height: auto;
  opacity: 0.5;
  cursor: pointer;
  transition: ease-in-out 0.3s;
}

.oski-masonry:hover {
  opacity: 1;
  transition: ease-in-out 0.3s;
}

.uknt-grid {
  margin-left: 0 !important;
  padding-left: 0 !important;
}

.oski-banner-edit {
  position: absolute;
  top: 15px;
  right: 15px;
  filter: drop-shadow(1px 1px 9px rgba(0, 0, 0, 1));
}

.oski-img-clickable {
  cursor: pointer;
  transition: ease-in-out 0.3s;
}

.oski-img-clickable:hover + .oski-text-clickable {
  opacity: 0.5;
  color: white;
  background-color: rgba(0, 0, 0, 1);
  transition: ease-in-out 0.3s;
}

.oski-text-clickable {
  position: absolute;
  text-align: center !important;
  top: 90px;
  left: 17%;
  border-radius: 2px;
  width: 200px !important;
  color: transparent;
  transition: ease-in-out 0.3s;
  cursor: pointer;
}

.oski-text-clickable:hover {
  color: white;
  background-color: rgba(0, 0, 0, 0.5);
  transition: ease-in-out 0.3s;
}

.oski-add-image {
  position: absolute;
  top: 65px;
}

.customModalCRM-light {
  background-color: rgba(250, 250, 250, 1) !important;
  color: #262626;
  padding: 30px !important;
  min-width: 30%;
  border-radius: 5px;
}

.customModalCRM-dark {
  background-color: rgba(0, 0, 0, 0.9) !important;
  color: #ffffff;
  padding: 30px !important;
  min-width: 30%;
  border-radius: 5px;
}

.prospect-modal-title {
  color: var(--secondary-color);
  text-transform: uppercase;
  font-weight: 550;
}

/* medium devices */
@media screen and (max-width: 960px) {
  .position-absolute-profile-img {
    position: absolute;
    top: -500%;
    right: -25%;
  }
}

/* small devices */
@media screen and (max-width: 640px) {
  .position-absolute-profile-img {
    position: absolute;
    top: -400%;
    right: -20%;
  }
}

.diinco-profile-info-container {
  position: relative;
  pointer-events: none;
}
.diinco-profile-info-container button.diinco {
  position: absolute;
  right: 20px;
  margin: auto;
  max-height: 50px;
  top: 0px;
  bottom: 0px;
  cursor: pointer;
  pointer-events: all;
}
