/* BUTTON */
.cj-icon-button {
  border-radius: 50%;
  /* margin: auto; */
  border-width: 0px;
  cursor: pointer;
  background-color: transparent !important;
  padding: 0px;
}

.cj-icon-button-size-x-small img,
.cj-icon-button-size-x-small svg {
  width: 10px !important;
  height: 10px !important;
  vertical-align: middle;
}

.cj-icon-button-size-small img,
.cj-icon-button-size-small svg {
  width: 17px;
  height: 17px;
}

.cj-icon-button-size-mini img,
.cj-icon-button-size-mini svg {
  width: 14px;
  height: 14px;
}

.cj-icon-button-size-large img,
.cj-icon-button-size-large svg {
  width: 64px;
  height: 64px;
}

.cj-icon-button-icon-container {
  width: fit-content;
  height: fit-content;
  margin: auto;
}

.cj-icon-button-variant-filled,
.cj-icon-button-variant-filled svg path {
  color: var(--text-light-white);
  fill: var(--text-light-white) !important;
}

.cj-icon-button-icon-color-light,
.cj-icon-button-icon-color-light svg path {
  color: #fff;
  fill: #fff !important;
}

.cj-icon-button-icon-color-dark,
.cj-icon-button-icon-color-dark svg path {
  color: #757474;
  fill: #757474 !important;
}

.cj-icon-button-icon-color-grey,
.cj-icon-button-icon-color-grey svg path {
  color: #cbcbcb;
  fill: #cbcbcb !important;
}

.cj-icon-button-icon-color-secondary,
.cj-icon-button-icon-color-secondary svg path {
  color: var(--secondary-color);
  fill: var(--secondary-color) !important;
}

.cj-icon-button-icon-color-primary,
.cj-icon-button-icon-color-primary svg path {
  color: var(--primary-color);
  fill: var(--primary-color) !important;
}

/* ripple */
.cj-ripple {
  position: relative;
  overflow: hidden;
}

.cj-ripple .cj-rippleContainer {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.cj-ripple .cj-rippleContainer span {
  transform: scale(0);
  border-radius: 100%;
  position: absolute;
  opacity: 0.75;
  background-color: #ffffff;
  animation: rippleAnim 850ms;
}

@keyframes rippleAnim {
  to {
    opacity: 0;
    transform: scale(2);
  }
}
