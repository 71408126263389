.diinco-tyc-container {
  height: 100%;
}

.dc-active {
  color: coral !important;
}

.diinco-tyc-image {
  width: 100%;
}

.diinco-tyc-card {
  text-align: left;
  width: 80%;
  background-color: rgba(var(--bg-color-rgb2), 0.9);
  border-radius: 5px;
  padding: 30px;
}

.diinco-tyc-card-title {
  color: var(--primary-color);
  margin-left: 20px;
  margin-top: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.37;
  letter-spacing: 0.9px;
  font-size: 25px;
}

.diinco-tyc-card-text {
  font-size: 17px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.35;
  letter-spacing: 0.6px;
  margin: 20px 20px 40px;
  color: var(--text-color);
  text-align: justify;
  text-justify: inter-word;
}

.leo-terms-back {
  background-image: url('../assets/cut-tyc.jpg');
  background-position: center;
  /* Center the image */
  background-repeat: no-repeat;
  /* Do not repeat the image */
  background-size: cover;
  /* background-size: calc(100vw +20px) !important; */
}

.leo-list li {
  overflow-y: hidden !important;
}
