.swiper-project-gallery {
  width: 100%;
  margin-top: 20px;
}

.leo-swipes {
  height: 196px;
  width: 260px;
  /* background-color: rgba(1,0,0, 1); */
}

.leo-swipes img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.customModalGalleryProject {
  background-color: rgba(0, 0, 0, 1);
  border-radius: 5px;

  padding: 35px 45px;
  /* min-width: 600px; */
  width: 100%;
}
