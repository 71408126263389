.title-close-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 10px;
}

.album-title {
  font-size: 20px;
  text-transform: uppercase;
  color: var(--text-gray-color);
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.close-button {
  width: 25px;
  height: 25px;
}
img.diinco-slide-cover {
  object-fit: cover;
  width: 100%;
}

.album-photo-container {
  width: 80%;
  margin-left: 10%;
  object-fit: cover;
  min-height: 300px;
  max-height: 300px;
  min-width: 80%;
  max-width: 80%;
}

.image-description {
  margin-top: 20px;
}

.other-album-container {
  margin-top: 20px;
  overflow: auto;
  white-space: nowrap;
}

.other-album-container h5 {
  font-size: 18px;
  text-transform: uppercase;
  color: var(--text-gray-color);
  /* margin-bottom: 10px; */
}

.mini-photo-container {
  /* background-color: darkcyan; */
  width: fit-content !important;
  margin-top: 10px;
}

.mini-photo {
  width: 50px;
  height: 50px;
  object-fit: cover;
}

.mini-photo-selected {
  border-color: var(--secondary-color);
  border-width: 2px;
  border-style: solid;
}

.mini-album-container {
  width: fit-content !important;
}

.mini-album-photo {
  width: 180px;
  height: 120px;
  object-fit: cover;
}

.mini-album-title {
  text-transform: uppercase;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 180px;
}

.photo-swiper {
  width: 70%;
  max-height: 100%;
}
