.oski-title {
  font-family: OpenSans;
  font-size: 30px;
  font-weight: bold;
}

.leo-documents-left {
  background-color: transparent;
}

.leo-documents-right {
  border-radius: 5px;
  border: solid 1px #fff;
  background-color: transparent;
  padding: 30px 30px 60px 30px;
  contain: content;
}

[data-theme='light'] .leo-documents-right {
  border: solid 1px black;
}

.folder-dark img {
  filter: invert(100%) sepia(12%) saturate(3293%) hue-rotate(156deg)
    brightness(110%) contrast(105%);
}

.folder-plus-dark img {
  filter: invert(100%) sepia(12%) saturate(3293%) hue-rotate(156deg)
    brightness(110%) contrast(105%);
}

.folder-plus-light img {
  filter: invert(56%) sepia(82%) saturate(2108%) hue-rotate(335deg)
    brightness(90%) contrast(103%);
}

.folder-dark.active img,
.folder-light.active img {
  filter: brightness(0) saturate(100%) invert(82%) sepia(44%) saturate(814%)
    hue-rotate(327deg) brightness(101%) contrast(95%);
}

.folder-light img {
  filter: invert(70%);
}

.folder-dark img,
.folder-light img {
  width: 90px;
  height: 70.02px;
  margin-left: auto;
  margin-right: auto;
}

.folder-plus-dark img,
.folder-plus-light img {
  width: 52.2px;
  height: 38.7px;
  margin-left: auto;
  margin-right: auto;
  cursor: pointer;
}

.folder-dark,
.folder-light {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.folder-plus-dark,
.folder-plus-light {
  display: flex;
  flex-direction: column;
}

.folder-title {
  font-family: OpenSans;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 0.45px;
  text-align: center;
  color: var(--text-color);
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;

  margin-bottom: 0px !important;
}

.file-container-footer {
  font-family: OpenSans;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.35;
  letter-spacing: 0.6px;
  text-align: center;
  color: var(--text-color);
  width: 100%;
  margin-top: 70px;
}

.file-container {
  display: grid;
  grid-auto-rows: 1fr;
  grid-template-columns: 4fr 2fr 1fr;
  gap: 0px 0px;
  margin-bottom: 15px;
  width: 100%;
}

.file-name {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 15px;
  width: 100%;
  cursor: pointer;
}

.file-size {
  font-family: OpenSans;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  margin-left: 10px;
  color: var(--text-color);
  white-space: nowrap;
  overflow: hidden;
}

.file-name p {
  font-family: OpenSans;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.35;
  letter-spacing: 0.6px;
  text-align: left;
  color: var(--text-color);
  height: 22px;

  margin-bottom: 0px;

  max-width: 350px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.file-name p:hover {
  text-decoration: underline;
}

.file-date {
  font-family: OpenSans;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.35;
  letter-spacing: 0.6px;
  text-align: center;
  color: var(--text-color);

  display: flex;
  justify-content: center;
  align-items: center;
}

.customModalFolder {
  background-color: rgba(var(--bg-color-rgb), 1);
  padding: 25px 35px;
  min-width: 250px;
  border-radius: 5px;
}

.title-new-folder {
  font-family: OpenSans;
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.35;
  letter-spacing: 0.6px;
  text-align: left;
  color: #f9b74e;
  margin-bottom: 10px;
}

.options-folder button span {
  color: var(--text-color);
}

.options-folder button {
  background-color: transparent;
  width: 30px;
  height: 30px;
  padding: 0px;
  cursor: pointer;
  border: none;
}

.options-folder {
  position: relative;
  right: -115px;
  top: -80px;
  width: 30px;
  height: 0px;
}

.dropdownFolders {
  height: 33px !important;
  min-width: 220px !important;
  width: 220px !important;
  border-radius: 5px !important;
}

.dropdownFolders:hover {
  border-radius: 5px !important;
}

.dropdownFolders::before {
  border-radius: 5px !important;
}

.dropdownFolders ul li a {
  text-decoration: none !important;
}

.customModalDeleteFolder {
  background-color: var(--card-color-yellow);
  padding: 20px 25px;
  min-width: 180px;
  border-radius: 5px;
}

.customModalDeleteFolder .title-new-folder {
  text-align: center;
  color: black;
  font-family: OpenSans;
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.35;
  letter-spacing: 0.6px;
}

.customModalDeleteFolder button img {
  filter: invert(100);
}

.leo-file-plus-dark {
  filter: invert(100%) sepia(12%) saturate(3293%) hue-rotate(156deg)
    brightness(110%) contrast(105%);
}

.leo-file-plus,
.leo-file-plus-dark {
  width: 29.43px;
  height: 36.81px;
  cursor: pointer;
  position: absolute;
  top: 20px;
  right: 20px;
}

.file-delete {
  font-family: OpenSans;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.35;
  letter-spacing: 0.6px;
  text-align: left;
  color: var(--text-color);

  display: none;
  height: 22px;
}

.file-delete a {
  width: 20px;
  height: 20px;
  background-color: #cbcbcb;
}

.file-delete a svg {
  color: black;
}

.file-delete a svg path {
  fill: black;
}

.file-container:hover .file-delete {
  display: block;
}

.title-folder-right {
  font-family: OpenSans;
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.35;
  letter-spacing: 0.6px;
  text-align: left;
  color: var(--text-color);

  padding-left: 38px;
  margin-bottom: 15px;
}

.file-edit {
  font-family: OpenSans;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.35;
  letter-spacing: 0.6px;
  text-align: left;
  color: var(--text-color);

  display: none;
  height: 22px;
  margin-left: 15px;
}

.file-edit a {
  width: 20px;
  height: 20px;
  background-color: #cbcbcb;
}

.file-edit a svg {
  color: black;
}

.file-edit a svg path {
  fill: black;
}

.file-name:hover .file-edit {
  display: block;
}

.file-edit.folder {
  display: block !important;
  margin-left: 5px;
}

.folder-name {
  display: flex;
  align-items: center;
  justify-content: center;
}

.rename-file-input {
  width: 100%;
  margin-left: 0px;
  margin-right: 10px;
  margin-bottom: 0px;
  background-color: transparent;
  border-style: none;
  border-bottom: solid 2px var(--primary-color);
  color: var(--text-color);
  opacity: 0.8;
  text-align: left;
  font-size: 18px;
  font-weight: 550;

  display: none;
}

.rename-folder-input {
  width: 100%;
  margin-left: 0px;
  margin-right: 0px;
  margin-bottom: 0px;
  margin-top: 5px;
  background-color: transparent;
  border-style: none !important;
  border-bottom: solid 2px var(--secondary-color);
  color: var(--text-color);
  opacity: 0.8;
  text-align: left;
  font-size: 18px;
  font-weight: 550;

  display: none;
}

.rename-file-input:focus-visible {
  outline: none !important;
  border-bottom: solid 2px var(--secondary-color) !important;
}

.rename-file-input:focus {
  border-bottom: solid 2px var(--secondary-color);
}

.rename-folder-input:focus-visible {
  outline: none !important;
  border-bottom: solid 2px var(--secondary-color) !important;
}

.rename-folder-input:focus {
  border-bottom: solid 2px var(--secondary-color);
}

.uk-progress.leo-progress::-webkit-progress-value {
  background-color: #fff;
}

[data-theme='light'] .uk-progress.leo-progress::-webkit-progress-value {
  background-color: var(--background-button);
}

.uk-progress.leo-progress::-webkit-progress-bar {
  background-color: black;
  border: 1px #fff solid;
}

[data-theme='light'] .uk-progress.leo-progress::-webkit-progress-bar {
  background-color: white;
  border: 1px black solid;
}
.uk-progress.leo-progress::-moz-progress-bar {
  background-color: #fff;
}
.uk-progress.leo-progress::-ms-fill {
  background-color: #fff;
}
