.main-container {
  color: var(--text-gray-color);
  padding: 20px;
  display: flex;
}

.user-image {
  min-width: 80px;
  max-width: 80px;
  min-height: 80px;
  max-height: 80px;
  margin-left: 10px;
}

.payment-info-container {
  width: 100%;
  margin-left: 20px;
}

.payment-title {
  color: var(--text-gray-color);
  text-transform: uppercase;
  font-weight: 550;
  margin-bottom: 10px;
}

.payment-content {
  margin-bottom: 10px;
}

.floating-icon-container {
  display: flex;
  align-items: flex-end;
}

.floating-icon {
  min-width: 30px;
  max-width: 30px;
  margin-right: 10px;
}

.leo-container:hover {
  background-color: #5d616363;
  opacity: 0.5;
  cursor: pointer;
}

.modalPayment-dark {
  background-color: rgba(0, 0, 0, 0.9) !important;
  padding: 60px 30px !important;
  min-width: 24%;
  max-width: 500px !important;
  border-radius: 5px;
}

.modalPayment {
  background-color: #fafafa !important;
  padding: 60px 30px !important;
  min-width: 24%;
  max-width: 500px !important;
  border-radius: 5px;
}

.modalPayment-dark .l-modal-title {
  color: var(--card-color-yellow);
  text-transform: uppercase;
  font-weight: 650;
  margin-bottom: 20px;
  text-align: center;
}

.modalPayment .l-modal-title {
  color: var(--primary-color);
  text-transform: uppercase;
  font-weight: 650;
  margin-bottom: 20px;
  text-align: center;
}

.leo-content {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
}

.leo-margin {
  margin-top: 15px !important;
}

.modalPayment .leo-content {
  color: #262626;
}

.modalPayment-dark .leo-content {
  color: white;
}

.leo-status {
  background-color: #262626;
  padding: 7px 16px;
  border-radius: 5px;
  min-width: 80px;
  color: #fbb857;
  text-align: center;
}

.modalPayment .leo-important-text {
  color: #ea6732 !important;
}

.modalPayment-dark .leo-important-text {
  color: #fbb857 !important;
}

.leo-important-text {
  text-align: center;
  text-transform: uppercase;
  max-width: 380px;
}

.leo-button-container {
  width: 100%;
  margin-top: 30px;
  display: flex;
  justify-content: space-around;
  padding: 0px 13px;
}

.leo-button-black {
  padding: 11px 36px;
  border-radius: 5px;
  background-color: #262626;
  color: #fbb857;
  min-width: 120px;
  border: none;
  cursor: pointer;
}

.leo-button-orange {
  padding: 11px 36px;
  border-radius: 5px;
  background-color: #ea6732;
  color: #000000;
  min-width: 143px;
  border: none;
  cursor: pointer;
}

.modalAccept {
  background-color: #f9b74e;
  min-width: 200px;
  border-radius: 5px;
  padding: 35px 20px 0px 20px;
}

.modalReject {
  background-color: #f9b74e;
  min-width: 200px;
  border-radius: 5px;
  padding: 35px 20px 20px 20px;
}

.leo-text-modal {
  color: #000000 !important;
  font-family: OpenSans;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.15;
  letter-spacing: 0.6px;
  max-width: 230px;
  text-align: center;
}
