.oski-customGallery-cover {
  width: 100% !important;
  height: 350px;
  display: flex;
  justify-content: center;
}

.oski-customGallery-cover a {
  width: 90%;
  height: 350px;
  display: flex;
  justify-content: center;
}

.oski-customGallery-cover-img {
  width: 100%;
  object-fit: contain;
  height: 350px;
  cursor: pointer;
}

.oski-customGallery-miniPhoto {
  width: 80px;
  object-fit: cover;
  height: 60px;
}

.mini-photo-container.active {
  border: 3px solid var(--golden-color);
}
