.dc-dropzone-container {
  text-align: right;
}

.dc-dropzone-files {
  border: 2px solid var(--hover-color);
  padding: 30px 5px 30px;
  color: var(--text-gray-color);
  text-align: center;
}

.dc-dropzone-files-rejected {
  border: 2px solid var(--red-color);
  padding: 30px 5px 30px;
  color: var(--text-gray-color);
  text-align: center;
}

.dc-dropzone-files-button {
  cursor: pointer;
  border: none;
  background-color: var(--primary-color);
  color: var(--text-gray-color);
  text-align: center;
  font-size: 15px;
  margin-left: auto;
  margin-right: 0;
  border-radius: 5px;
  padding: 15px 10px 15px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.dc-file-item-container {
  margin-bottom: 10px;
}

.dc-file-item-icon {
  width: 20px !important;
  height: auto;
  min-width: 20px;
}

.dc-file-item-text {
  margin: 0px 20px 0px;
  /* color: var(--text-gray-color); */
  width: 75%;
  text-align: left;
  font-size: 20px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.dc-buttonless {
  background: transparent !important;
  border: none;
  cursor: pointer;
}

.cj-error-message-DZCI {
  font-size: 12px;
  color: var(--red-color);
  opacity: 1;
  font-weight: 400;
  text-align: left;
}

.cj-error-message-DZCI span {
  margin-right: 3px;
}
