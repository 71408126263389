/* .cj-card-component{
    
} */

/* VARIANTS */
/* filled */
.cj-card-component-filled.cj-card-component-grey,
.cj-card-component-hover-filled.cj-card-component-hover-grey:hover {
  border-radius: 5px;
  background-color: #757474;
  border: 0px;
}

.cj-card-component-filled.cj-card-component-hover-outlined.cj-card-component-grey {
  border: solid 2px #757474;
}

.cj-card-component-filled.cj-card-component-lightgrey,
.cj-card-component-hover-filled.cj-card-component-hover-lightgrey:hover {
  border-radius: 5px;
  background-color: #aeaeae;
  border: 0px;
}

.cj-card-component-filled.cj-card-component-hover-outlined.cj-card-component-lightgrey {
  border: solid 2px #aeaeae;
}

.cj-card-component-filled.cj-card-component-transparent,
.cj-card-component-hover-filled.cj-card-component-hover-transparent:hover {
  border-radius: 5px;
  background-color: transparent;
  border: 0px;
}

.cj-card-component-filled.cj-card-component-hover-outlined.cj-card-component-transparent {
  border: solid 2px transparent;
}

.cj-card-component-filled.cj-card-component-secondary,
.cj-card-component-hover-filled.cj-card-component-hover-secondary:hover {
  border-radius: 5px;
  background-color: var(--secondary-color);
  border: 0px;
}

.cj-card-component-filled.cj-card-component-hover-outlined.cj-card-component-secondary {
  border: solid 2px var(--secondary-color);
}

.cj-card-component-filled.cj-card-component-primary,
.cj-card-component-hover-filled.cj-card-component-hover-primary:hover {
  border-radius: 5px;
  background-color: var(--primary-color);
  border: 0px;
}

.cj-card-component-filled.cj-card-component-hover-outlined.cj-card-component-primary {
  border: solid 2px var(--primary-color);
}

.cj-card-component-filled.cj-card-component-dark,
.cj-card-component-hover-filled.cj-card-component-hover-dark:hover {
  border-radius: 5px;
  background-color: #262626;
  border: 0px;
}

.cj-card-component-filled.cj-card-component-hover-outlined.cj-card-component-dark {
  border: solid 2px #262626;
}

.cj-card-component-filled.cj-card-component-light,
.cj-card-component-hover-filled.cj-card-component-hover-light:hover {
  border-radius: 5px;
  background-color: #fff;
  border: 0px;
}

.cj-card-component-filled.cj-card-component-hover-outlined.cj-card-component-light {
  border: solid 2px #fff;
}

/* outlined */
.cj-card-component-outlined.cj-card-component-grey,
.cj-card-component-hover-outlined.cj-card-component-hover-grey:hover {
  border-radius: 5px;
  border: solid 2px #757474;
  background-color: transparent;
}

.cj-card-component-outlined.cj-card-component-hover-filled.cj-card-component-hover-grey:hover {
  border: solid 2px #757474;
}

.cj-card-component-outlined.cj-card-component-lightgrey,
.cj-card-component-hover-outlined.cj-card-component-hover-lightgrey:hover {
  border-radius: 5px;
  border: solid 2px #aeaeae;
  background-color: transparent;
}

.cj-card-component-outlined.cj-card-component-hover-filled.cj-card-component-hover-lightgrey:hover {
  border: solid 2px #aeaeae;
}

.cj-card-component-outlined.cj-card-component-secondary,
.cj-card-component-hover-outlined.cj-card-component-hover-secondary:hover {
  border-radius: 5px;
  border: solid 2px var(--secondary-color);
  background-color: transparent;
}

.cj-card-component-outlined.cj-card-component-hover-filled.cj-card-component-hover-secondary:hover {
  border: solid 2px var(--secondary-color);
}

.cj-card-component-outlined.cj-card-component-primary,
.cj-card-component-hover-outlined.cj-card-component-hover-primary:hover {
  border-radius: 5px;
  border: solid 2px var(--primary-color);
  background-color: transparent;
}

.cj-card-component-outlined.cj-card-component-hover-filled.cj-card-component-hover-primary:hover {
  border: solid 2px var(--primary-color);
}

.cj-card-component-outlined.cj-card-component-dark,
.cj-card-component-hover-outlined.cj-card-component-hover-dark:hover {
  border-radius: 5px;
  border: solid 2px #262626;
  background-color: transparent;
}

.cj-card-component-outlined.cj-card-component-hover-filled.cj-card-component-hover-dark:hover {
  border: solid 2px #262626;
}

.cj-card-component-outlined.cj-card-component-light,
.cj-card-component-hover-outlined.cj-card-component-hover-light:hover {
  border-radius: 5px;
  border: solid 2px #fff;
  background-color: transparent;
}

.cj-card-component-outlined.cj-card-component-hover-filled.cj-card-component-hover-light:hover {
  border: solid 2px #fff;
}
