input.diinco-input-light {
  border-bottom: 2px solid var(--border-input) !important;
  color: var(--text-color) !important;
}

input.diinco-input-light::placeholder {
  color: var(--placeholder-color) !important;
}

input.diinco-selected {
  border-bottom-color: var(--secondary-color) !important;
  caret-color: var(--text-gray-color);
}

button.diinco {
  padding: 15px 10px;
  background-color: #f36f39;
  color: #fff;
  text-transform: uppercase;
  border-width: 0px;
  margin-bottom: 10px;
  cursor: pointer;
  border-radius: 5px;
}

input.diinco:focus {
  border-color: rgba(0, 0, 0, 0);
}

input.diinco-input-dark,
textarea.diinco-textarea-dark {
  background-color: transparent !important;
  border: none;
  border: 2px solid var(--hover-color-alt) !important;
  font-size: 15px;
  color: var(--text-gray-color) !important;
  border: none;
}

input.diinco-input-dark::placeholder,
textarea.diinco-textarea-dark::placeholder {
  color: var(--text-gray-color) !important;
  opacity: 0.5;
}

input.diinco-selected-dark {
  border-bottom-color: var(--secondary-color) !important;
  caret-color: var(--secondary-color);
}

.dc-date-placeholder {
  color: var(--text-gray-color) !important;
  opacity: 0.5;
  padding-left: 10px;
}

.diinco-input-dark::-webkit-calendar-picker-indicator {
  filter: invert(1);
}

.diinco-input-code {
  text-align: center;
  border: 2px solid var(--border-color) !important;
  border-color: var(--text-light-color) !important;
  border-radius: 5px;
  color: var(--text-gray-color);
  margin-bottom: 5px !important;
}

.diinco-input-code::placeholder {
  color: var(--text-gray-color);
}

.diinco-input-code:focus {
  border: 2px solid var(--secondary-color-light) !important;
  color: var(--text-gray-color);
}

.diinco-input-code:hover {
  border: 2px solid var(--secondary-color) !important;
}

/* TEXTINPUT */
input.diinco,
textarea.diinco {
  background-color: transparent !important;
  border-color: rgba(0, 0, 0, 0) !important;
  border-bottom: 2px solid var(--border-input) !important;
}

.cj-input-margin-bottom {
  margin-bottom: 33px !important;
}

.cj-input-label-container {
  position: relative;
  display: flex;
  flex-direction: column;
}

.cj-input-label-container textarea {
  height: auto;
  resize: vertical;
  min-height: 30px;
}

/* OUTLINED */
.cj-input-label-container-focus .cj-input-outlined:focus,
.cj-input-label-container-focus .cj-input-code:focus {
  box-shadow: 0 0 0 1px var(--secondary-color);
  border: 2px solid var(--secondary-color) !important;
}

.cj-input-outlined {
  background-color: transparent !important;
  border: none;
  color: var(--text-color) !important;
  border: none;
  border-radius: 5px;
}

.cj-input-outlined[aria-invalid='false'] {
  border: 2px solid var(--hover-color-alt) !important;
}

.cj-input-outlined[aria-invalid='true'] {
  border: 2px solid var(--red-color) !important;
}

/* STANDARD */
.cj-input-label-container-focus .cj-input-standard:focus {
  border-bottom-color: var(--secondary-color) !important;
}

.cj-input-label-container input:disabled,
.cj-input-label-container textarea:disabled,
.cj-input-label-container-disabled {
  opacity: 0.5;
}

.cj-input-standard:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 50px var(--bg-color) inset;
  /* Change the color to your own background color */
  -webkit-text-fill-color: var(--text-color);
}

.cj-input-standard:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0 50px var(--bg-color) inset;
  /*your box-shadow*/
  -webkit-text-fill-color: var(--text-color);
}

.cj-input-standard {
  border-bottom: 2px solid var(--hover-color-alt) !important;
  color: var(--hover-color-alt) !important;
  padding-left: 0;
  padding-right: 0;
}

textarea.cj-input-standard {
  padding-top: 0;
  padding-bottom: 0;
}

.cj-input-standard[aria-invalid='true'] {
  border-bottom: 2px solid var(--red-color) !important;
}

.cj-input-standard[aria-invalid='false'] {
  border-bottom: 2px solid var(--hover-color-alt) !important;
}

/* CODE */
.cj-input-code {
  background-color: transparent !important;
  color: #fff !important;
  border-radius: 5px;

  text-align: center;
  border: 2px solid var(--border-color) !important;

  /* border-radius: 5px;
	color: var(--text-gray-color);
	margin-bottom: 5px !important; */
}

.cj-input-code::placeholder {
  color: #fff !important;
  opacity: 0.5;
}

.cj-input-code[aria-invalid='false'] {
  border: 2px solid var(--border-color) !important;
}

.cj-input-code[aria-invalid='true'] {
  border: 2px solid var(--border-color) !important;
}

/* HELPER TEXT */

/* .cj-input-helper-text{
	color: var(--hover-color-alt) !important;
} */

.cj-input-error {
  color: var(--red-color) !important;
  opacity: 1 !important;
}

.cj-input-code + .cj-input-error {
  color: #fff !important;
  opacity: 1 !important;
}

.cj-input-error span span {
  margin-right: 3px;
}

.cj-helper-text {
  font-size: 12px;
  color: var(--text-color);
  opacity: 0.5;
}

/* LABEL */

.cj-input-label-container label {
  position: absolute;
  pointer-events: none;
  transform: translate(0, 12px) scale(1);
  transform-origin: top left;
  transition: 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  color: var(--hover-color-alt);
  /* font-size: 16px; */
  line-height: 1;
  left: 10px;
  opacity: 1;
}

[data-theme='light'] .cj-input-label-container label {
  opacity: 1;
}

.cj-input-label-container textarea + label {
  transform: translate(0, 0px) scale(1);
}

.cj-input-label-container:focus-within label {
  transform: translate(0, -6px) scale(0.8);
  color: var(--secondary-color);
  opacity: 1;
  font-size: 15px;
  padding: 0;
}

.cj-input-label-container:focus-within textarea + label,
.cj-input-label-container textarea + .filled {
  transform: translate(0, -12px) scale(0.8) !important;
}

.cj-input-outlined:focus-within label {
  padding: 0 5px;
}

.cj-input-label-container:focus-within label span {
  opacity: 1;
}

.cj-input-label-container .filled {
  transform: translate(0, -6px) scale(0.8);
  opacity: 1;
  font-size: 15px;
  padding: 0;
}

.cj-input-label-container .filled span {
  opacity: 0.5;
  transition: 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
}

[data-theme='light'] .cj-input-label-container .filled span {
  opacity: 1;
}

/* standard */
.cj-input-label-container .cj-input-standard[aria-invalid='true'] + label {
  color: var(--red-color);
  opacity: 1;
}

.cj-input-label-container .cj-input-standard + label {
  background-color: transparent !important;
}

.cj-input-standard + label {
  left: 0;
}

/* outlined */
.cj-input-label-container .cj-input-outlined[aria-invalid='true'] + label {
  color: var(--red-color);
  opacity: 1;
}

.cj-input-outlined + label {
  left: 10px;
}

.cj-input-outlined .filled {
  padding: 0 5px;
}

[data-theme='dark'] .cj-input-outlined + .filled span {
  opacity: 1;
}

/* INPUT */
.cj-input-label-container input {
  /* height: 64px; */
  /* border-radius: 4px; */
  border: none;
  /* padding: 24px 16px 4px 16px; */
  /* font-size: 16px; */
  /* line-height: 1; */
  /* outline: none; */
  /* box-shadow: none; */
  transition: 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
}

/* ICON BUTTONS */
/* password */
.cj-icon-eye {
  position: absolute !important;
  right: 0;
  top: 10px;
  width: auto !important;

  display: inline-flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  background-color: transparent;
  outline: 0px;
  border: 0px;
  margin: 0px;
  cursor: pointer;
  user-select: none;
  vertical-align: middle;
  appearance: none;
  text-decoration: none;
  text-align: center;
  flex: 0 0 auto;
  border-radius: 50%;
  overflow: visible;
  color: rgb(255, 255, 255);
  padding: 1px;
  font-size: 1.125rem;
}

.cj-icon-eye svg path {
  fill: var(--text-color);
  stroke-width: 1;
}

.cj-icon-eye svg {
  fill: var(--text-color);
  opacity: 0.5;
  stroke-width: 1;
}

.cj-input-label-container input::-ms-reveal,
.cj-input-label-container input::-ms-clear {
  display: none;
}

/* datepicker */

[data-theme='dark']
  .cj-input-label-container
  input[type='date']::-webkit-calendar-picker-indicator {
  color: var(--text-color);
  fill: var(--text-color);
  filter: invert(1);
}

.cj-input-label-container
  input[type='date']::-webkit-calendar-picker-indicator {
  opacity: 0.5;
}

/* number arrows */
/* Chrome, Safari, Edge, Opera */
.cj-input-label-container input::-webkit-outer-spin-button,
.cj-input-label-container input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.cj-input-label-container input[type='number'] {
  -moz-appearance: textfield;
}

/* SELECT */
.cj-error-select-border-bottom .cj-react-select__control {
  border-bottom: 2px solid rgb(201, 70, 67) !important;
}

.cj-error-select-border .cj-react-select__control {
  border: 2px solid rgb(201, 70, 67) !important;
}

.cj-error-select-border label,
.cj-error-select-border-bottom label {
  color: var(--red-color);
  opacity: 1;
}

.cj-react-select__option--is-focused {
  background-color: var(--secondary-color) !important;
}

.cj-react-select__menu {
  z-index: 12 !important;
}
