.cj-button-component-container {
  margin-bottom: 33px;
}

.cj-button-component-container-no-margin {
  margin-bottom: 0px;
}

.cj-button-component {
  border-radius: 5px;
  margin: auto;
  border-width: 0px;
  cursor: pointer;
}

.cj-button-component.disabled-border {
  border-radius: 0px !important;
}

/* SIZES */
.cj-button-component-small {
  padding: 10px 40px;
  width: fit-content;
}

.cj-button-component-medium {
  padding: 10px 50px;
  width: fit-content;
}

.cj-button-component-large {
  padding: 10px 160px;
  width: fit-content;
}

.cj-button-component-auto {
  padding: 10px;
  width: 100%;
}
/* VARIANTS */
.cj-button-component-text {
  background-color: transparent !important;
}

.cj-button-component-outlined {
  background-color: transparent !important;
  border: 2px solid;
}

.cj-button-component-underlined {
  background-color: transparent !important;
  border-bottom: 2px solid;
  border-radius: 0px;
  color: var(--text-white-color);
}

/* COLORS */
.cj-button-component-color-primary.cj-button-component-contained {
  background-color: var(--primary-color);
  color: #ffff;
}

.cj-button-component-color-primary.cj-button-component-underlined {
  border-color: var(--primary-color);
}

.cj-button-component-color-primary.cj-button-component-text {
  color: var(--primary-color);
}

.cj-button-component-color-primary.cj-button-component-outlined {
  border-color: var(--primary-color);
  color: var(--text-white-color);
}

.cj-button-component-color-secondary {
  background-color: var(--secondary-color);
  color: #262626;
}

.cj-button-component-color-secondary.cj-button-component-underlined {
  border-color: var(--secondary-color);
  color: var(--text-white-color);
}

.cj-button-component-color-secondary.cj-button-component-text {
  color: var(--secondary-color);
}

.cj-button-component-color-secondary.cj-button-component-outlined {
  border-color: var(--secondary-color);
  color: var(--text-white-color);
}

.cj-button-component-color-dark {
  background-color: var(--bg-dark-gray);
  color: var(--secondary-color);
}

.cj-button-component-color-dark.cj-button-component-underlined {
  border-color: var(--bg-dark-gray);
  color: var(--text-white-color);
}

.cj-button-component-color-dark.cj-button-component-text {
  color: var(--bg-dark-gray);
}

.cj-button-component-color-dark.cj-button-component-outlined {
  border-color: var(--bg-dark-gray);
  color: var(--text-white-color);
}

.cj-button-component-color-grey {
  background-color: #aeaeae;
  color: #262626;
}

[data-theme='dark'] .cj-button-component-color-grey {
  background-color: #6b6e6f;
  color: #262626;
}

.cj-button-component-color-grey.cj-button-component-underlined {
  border-color: #6b6e6f;
  color: var(--text-white-color);
}

/* disabled */
.cj-button-component-disabled {
  cursor: auto;
  opacity: 0.7;
}

.rename-input:focus:not([tabindex^='-']) {
  outline: none;
  outline-offset: 0px;
}
