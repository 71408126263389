.member-avatar-wrapper {
  display: flex;
  align-items: center;
  border-radius: 50%;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.member-avatar-wrapper-border {
  border: 4px solid var(--secondary-color);
}

.member-avatar-wrapper-size-small,
.member-avatar-wrapper-size-small .avatar-img-wrapper,
.member-avatar-wrapper-size-small .avatar-initials-wrapper {
  width: 30px;
  height: 30px;
}

.member-avatar-wrapper-size-medium,
.member-avatar-wrapper-size-medium .avatar-img-wrapper,
.member-avatar-wrapper-size-medium .avatar-initials-wrapper {
  width: 50px;
  height: 50px;
}

.member-avatar-wrapper-size-large,
.member-avatar-wrapper-size-large .avatar-img-wrapper,
.member-avatar-wrapper-size-large .avatar-initials-wrapper {
  width: 120px;
  height: 120px;
}

.member-avatar-wrapper-size-x-large,
.member-avatar-wrapper-size-x-large .avatar-img-wrapper,
.member-avatar-wrapper-size-x-large .avatar-initials-wrapper {
  width: 200px;
  height: 200px;
}

.member-avatar-wrapper .avatar-img-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}

.member-avatar-wrapper .avatar-img-wrapper .avatar-img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  border-radius: 50%;
}

.member-avatar-wrapper .avatar-initials-wrapper {
  display: inline-block;
  vertical-align: middle;
  position: relative;
  background-color: #535556;
  color: #ffffff;
  border-radius: 50%;
}

[data-theme='light'] .member-avatar-wrapper .avatar-initials-wrapper {
  background-color: #626566;
}

.member-avatar-wrapper .avatar-initials-wrapper .avatar-initials {
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  font-size: 12px;
}

/* .member-avatar-wrapper .name-wrapper {
    display: flex;
    flex-direction: column;
  }
  .member-avatar-wrapper .name-wrapper .avatar-name {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    white-space: nowrap;
  }
  .member-avatar-wrapper .name-wrapper .avatar-status {
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
  }
  .member-avatar-wrapper .name-wrapper .avatar-status.status-success {
    color: #59933b;
  }
  .member-avatar-wrapper .name-wrapper .avatar-status.status-danger {
    color: #ea5455;
  } */
