:root,
[data-input-theme='light'] {
	--primary-color: #f36f39;
	/* ya */
	--secondary-color: #fbb857;
	/* ya */
	--bg-color: #ffffff;
	/* ya */
	--bg-color-rgb: 255, 255, 255;
	/* ya */
	--bg-color-rgb2: 255, 255, 255;
	/* ya */
	--bg-color-alt: #cbcbcb;
	--bg-color-alt-rgb: 203, 203, 203;
	/* ya */
	--bg-dark-color: #ea6732;
	--bg-dark-gray: #262626;
	/* ya */
	--bg-button-gray: #262626;
	--hover-color: #6b6e6f;
	--text-gray-color: #7b7b7b;
	/* ya */
	--text-white-color: #262626;
	/* ya */
	--text-color: #262626;
	/* ya */
	--text-light-white: #ffffff;
	/* ya */
	--text-date-color: #7b7b7b;
	/* ADDED */
	--secondary-color-light: #ffdfab;
	--secondary-color-dark: #c98429;
	--primary-color-light: #f1926d;
	--primary-color-dark: hsl(17, 68%, 52%);
	--primary-color-alt: #ea6732;
	/* ya */
	--placeholder-color: #6b6e6f;
	/* ya */

  --golden-color: #fbb857;
  --green-color: #3abd5a;
  --red-color: rgb(201, 70, 67);
  --hover-color-alt: #707070;
  --border-color: #fbb857;
  --card-color: #6b6e6f;
  /* ya */
  --card-color-yellow: #f9b74e;
  /* ya */
  --hover-drop: #b8b8b8;
  /* ya */

  --border-input: #707070;
  /* ya */

  --background-button: #535556;

  --switch-title-color: #f36f39;

  /*Footer height*/
  --footer-height: 70px;

	--text-invert-color: #fff;

	 
}

[data-theme='dark'],
[data-input-theme='dark'] {
  --primary-color: #f36f39;
  /* ya */
  --secondary-color: #fbb857;
  /* ya */
  --bg-color: #000000;
  /* ya */
  --bg-color-rgb: 38, 38, 38;
  /* ya */
  --bg-color-rgb2: 0, 0, 0;
  /* ya */
  --bg-dark-color: #262626;
  --bg-dark-gray: #262626;
  /* ya */
  --bg-color-alt: #cbcbcb;
  --bg-color-alt-rgb: 203, 203, 203;
  /* ya */
  --bg-button-gray: #cbcbcb;
  --hover-color: #6b6e6f;
  --text-gray-color: #cbcbcb;
  --text-white-color: #ffffff;
  --text-color: #ffffff /* ya */;
  --text-date-color: #cbcbcb;
  /* ADDED */
  --secondary-color-light: #ffdfab;
  --secondary-color-dark: #c98429;
  --primary-color-light: #f1926d;
  --primary-color-dark: hsl(17, 68%, 52%);
  --primary-color-alt: #ea6732;
  /* ya */
  --placeholder-color: #ffffff;
  /* ya */
  --text-light-white: #ffffff;
  /* ya */

  --golden-color: #fbb857;
  --green-color: #3abd5a;
  --red-color: rgb(201, 70, 67);
  --hover-color-alt: #707070;
  --card-color: #262626;
  /* ya */
  --card-color-yellow: #f9b74e;
  /* ya */
  --hover-drop: #757474;
  /* ya */

  --border-input: #ffffff;
  /* ya */

  --background-button: #535556;

  --switch-title-color: #f9b74e;

  /*Footer height*/
  --footer-height: 70px;

  --text-invert-color: #000;
}

* {
  font-family: 'Open Sans', sans-serif !important;
  margin: 0;
}

html,
body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--bg-color);
  min-height: 100vh;
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

button.diinco-1 {
  padding: 15px 10px;
  background-color: var(--primary-color);
  color: var(--text-light-white);
  text-transform: uppercase;
  border-width: 0px;
  border-radius: 5px;
  margin-bottom: 10px;
  cursor: pointer;
}

button.diinco-2 {
  padding: 15px 10px;
  background-color: var(--secondary-color);
  color: var(--text-light-white);
  text-transform: uppercase;
  border-width: 0px;
  border-radius: 5px;
  margin-bottom: 10px;
  cursor: pointer;
}

.uk-tab a {
  color: var(--text-gray-color);
}

.uk-tab::before {
  border-bottom: solid 2px var(--secondary-color);
  /* background-color: var(--primary-color); */
}

.uk-tab > .uk-active > a {
  border-bottom: solid 2px var(--primary-color);
  color: var(--text-white-color);
}

.dc-bg-orange {
  background-color: var(--primary-color);
}

.dc-bg-yellow {
  background-color: var(--secondary-color);
}

.dc-fill-yellow {
  color: var(--secondary-color) !important;
}

.dc-bg-gray {
  background-color: var(--text-gray-color) !important;
}

.dc-bg-dark-gray {
  background-color: var(--bg-dark-gray);
}

.dc-text-white {
  color: var(--text-white-color) !important;
}

.dc-text-gray {
  color: var(--text-gray-color) !important;
}

.dc-text-dark-gray {
  color: var(--bg-dark-color) !important;
}

.dc-text-uppercase {
  text-transform: uppercase;
}

.dc-separator {
  height: 3px;
}

.swiper-pagination-bullet-active {
  background-color: var(--text-gray-color) !important;
}

.swiper-button-prev {
  color: var(--text-gray-color) !important;
}

.swiper-button-next {
  color: var(--text-gray-color) !important;
}

::-webkit-scrollbar {
  background-color: #929292;
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-thumb {
  background: #6b6e6f;
}

/* modal style */
.uk-modal-body {
  background-color: var(--bg-dark-color);
  color: var(--text-gray-color);
}

/* dropdown style */
.uk-dropdown {
  background-color: var(--bg-dark-color);
  color: var(--text-color);
  padding: 0px;
  border-radius: 5px;
  opacity: 0.9;
}

.l-background {
  background-color: var(--bg-color);
}

.l-background * {
  caret-color: var(--text-color) !important;
}

.uk-input,
.uk-select,
.uk-textarea {
  border: none !important;
}

.leo-tab {
  color: var(--text-color) !important;
}

.uk-tooltip {
  background-color: var(--secondary-color) !important;
  border-radius: 5px;
  border-style: solid;
  border-width: 1px;
  /* border-color: var(--hover-color); */
}
