.dc-info-img {
  height: 100%;
}

.dc-divider-light-gray {
  width: 12rem;
  border-top: 1px solid var(--text-gray-color);
  margin-top: 5rem;
}

/* ==== PROJECTS ==== */
.project-content {
  margin-top: 3rem;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  gap: 10px;
  grid-auto-rows: minmax(auto, auto);
}

.dc-face-project-title {
  color: var(--secondary-color);
  font-size: 2.6rem;
  font-weight: initial;
  text-transform: uppercase;
}

.dc-button-dark {
  color: var(--secondary-color);
  background-color: var(--bg-dark-gray);
  border-radius: 5px;
  border: none;
  padding: 10px 30px;
  cursor: pointer;
}

.dc-button-light {
  color: var(--bg-color);
  background-color: var(--secondary-color);
  border-radius: 5px;
  border: none;
  padding: 13px;
  cursor: pointer;
}

/* ANIMATION DIINCO FACE */
.dc-diinco-slideup-title {
  color: var(--primary-color-alt);
  opacity: 0.4;
  text-transform: uppercase;
  font-size: 60px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: 3.3px;
}

.dc-diinco-slideup {
  cursor: default;
  height: 100px;
}

.dc-diinco-slideup-text-1 {
  opacity: 0;
  transform: translateY(100%);
  transition: transform 0.5s, opacity 0.5s;
  color: var(--primary-color);
  cursor: default;
}

.dc-diinco-slideup-text-2 {
  opacity: 0;
  transform: translateY(100%);
  transition: transform 0.5s, opacity 0.5s;
  color: var(--text-color);
  cursor: default;
}

.dc-diinco-slideup-text-3 {
  opacity: 0;
  transform: translateY(100%);
  transition: transform 0.5s, opacity 0.5s;
  color: var(--secondary-color);
  cursor: default;
}

.dc-diinco-slideup:hover > .dc-diinco-slideup-title {
  -webkit-animation: slide-top 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275) both;
  animation: slide-top 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275) both;
}

.dc-diinco-slideup:hover > .dc-diinco-slideup-text-1,
.dc-diinco-slideup:hover > .dc-diinco-slideup-text-2,
.dc-diinco-slideup:hover > .dc-diinco-slideup-text-3 {
  -webkit-animation: slide-top 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275) both;
  animation: slide-top 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275) both;
}

@-webkit-keyframes slide-top {
  0% {
    transform: translateY(0);
    opacity: 0.3;
  }

  100% {
    transform: translateY(-50px);
    opacity: 1;
  }
}

@keyframes slide-top {
  0% {
    transform: translateY(0);
    opacity: 0.3;
  }

  100% {
    transform: translateY(-50px);
    opacity: 1;
  }
}

/* ANIMATION 2 */

.container-home {
  position: absolute;
  box-sizing: content-box;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  width: 80vw;
  height: 80vh;
  z-index: 2;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.container-home img {
  height: 170px;
}

.select-face .cj-react-select__control {
  border-bottom: 2px solid white !important;
}

.face-cards .album-card-container {
  padding-right: 0px;
  position: relative;
}

.swiper-cards {
  height: 330px !important;
}
