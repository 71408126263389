.diinco-stripe-form-container {
  padding: 20px 100px;
  margin: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
img.diinco-payment-plan-img {
  padding: 1rem;
  margin-bottom: 3rem;
  border-color: var(--text-color);
  border-radius: 0.5rem;
  border-width: 0.1rem;
  border-style: solid;
  aspect-ratio: 1;
  width: 20rem;
  height: 20rem;
  align-self: center;
  margin-left: auto;
  margin-right: auto;
  display: block;
  object-fit: scale-down;
}

.diinco-payment-plan-button {
  margin-top: 2rem;
}

.diinco-unauthorized-payment-intent {
  display: flex;
  flex-direction: column;
  padding: 20px;
  margin: 20px 40px;
  color: var(--text-color);
  font-size: 2rem;
  text-align: center;
}
