.banner-relative-layout {
  position: relative;
  width: 100%;
  /* background-color: #535556; */
  z-index: 9;

  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  height: 400px;
  /* background: rgb(2,0,36) !important;
	background: linear-gradient(0deg, rgba(2,0,36,1) 0%, rgba(0,0,0,1) 0%, rgba(9,9,121,0) 36%, rgba(0,212,255,0) 100%) !important; */
}

.dropdown-option {
  /* background-color: gold; */
  margin: 0px !important;
  padding-left: 15px;
  padding-top: 5px;
  padding-bottom: 5px;
  cursor: pointer;
}

.dropdown-option:hover {
  background-color: var(--hover-drop);
  color: var(--text-color);
}

.first-option {
  border-radius: 5px 5px 0px 0px;
}

.last-option {
  margin: 0px !important;
  border-radius: 0px 0px 5px 5px;
  /* padding-left: 0px;
    padding-top: 0px;
    padding-bottom: 0px; */
}

/* .banner-relative::after {
	position: absolute;
	content: "";
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	background: rgb(2,0,36) !important;
	background: linear-gradient(0deg, rgba(2,0,36,1) 0%, rgba(0,0,0,1) 0%, rgba(9,9,121,0) 36%, rgba(0,212,255,0) 100%) !important;
}

img.diinco-project-banner {
	height: 400px;
	background-color: var(--bg-color);
	object-fit: cover;
	/* filter: linear-gradient(0deg, rgba(2,0,36,1) 0%, rgba(0,0,0,1) 0%, rgba(9,9,121,0) 36%, rgba(0,212,255,0) 100%) !important;
} */

/* .leo-banner-edit {
	position: absolute;
	top: 12px;
	right: 12px;
}

.leo-banner-edit img {
	width: 18px;
}

.leo-banner-edit {
	border: #fff solid 1.5px;
	width: 29px;
	height: 29px;
	display: flex;
	align-content: center;
	align-items: center;
	justify-content: center;
	border-radius: 50%;

	cursor: pointer;
} */

.progress-absolute {
  position: absolute;
  width: 22%;
  margin-left: 5%;
  margin-top: 5%;
}

/* .dc-project-title {
	max-width: 65%;
} */

.project-name-absolute-layout {
  font-size: 30px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.37;
  letter-spacing: 1.8px;
  text-align: right;
  background-color: rgba(249, 183, 78, 0.8);
  text-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  padding-right: 20px;
  padding-left: 20px;
  position: absolute;
  bottom: 15px;
  right: 0px;
  /* background-color: red;  */
  width: auto;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.cj-project-dashboard-users {
  width: fit-content;
  max-width: 300px;
  padding: 30px;
  background-color: rgba(var(--bg-color-rgb), 0.9);
  border-radius: 5px;
}

.add-photo-project {
  height: 196px;
  width: 260px;
  border: 2px solid var(--primary-color);
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  align-content: center;
  cursor: pointer;
}

.add-photo-project2 {
  height: 200px;
  width: 100%;
  border: 2px solid var(--primary-color);
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  align-content: center;
  cursor: pointer;
  box-sizing: border-box;
}

.add-photo-project span {
  color: var(--primary-color);
}

.add-photo-project2 span {
  color: var(--primary-color);
}

.customModalCRM-light {
  background-color: rgba(250, 250, 250, 1) !important;
  color: #262626;
  padding: 30px !important;
  min-width: 30%;
  border-radius: 5px;
}

.customModalCRM-dark {
  background-color: rgba(0, 0, 0, 0.9) !important;
  color: #ffffff;
  padding: 30px !important;
  min-width: 30%;
  border-radius: 5px;
}

[data-theme='light'] .customModalCRM-dark {
  background-color: white !important;
  color: black;
  border-radius: 5px;
}

.cj-dropdown-button-icon svg path {
  fill: #000 !important;
}
