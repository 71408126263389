.diinco-calendar-container {
  width: 100%;
  height: auto;
  align-self: center;
}

.diinco-calendar-events-container {
  min-height: 100px;
  height: auto;
  background-color: var(--bg-dark-color);
  margin-bottom: 0px;
  color: var(--text-white-color);
  padding: 20px;
  position: relative;
  padding-right: 40px;
  display: flex;
  column-gap: 2px;
  flex-direction: column;
}

.dropdown-option {
  /* background-color: gold; */
  margin: 0px !important;
  padding-left: 15px;
  padding-top: 5px;
  padding-bottom: 5px;
  cursor: pointer;
}

.dropdown-option:hover {
  background-color: var(--hover-drop);
  color: var(--text-color);
}

.last-option-calendar {
  margin: 0px !important;
  border-radius: 0px 0px 5px 5px;
}

.first-option-calendar {
  margin: 0px !important;
  border-radius: 5px 5px 0px 0px;
}

.last-option:hover {
  background-color: var(--hover-drop);
  color: var(--text-color);
}

.diinco-calendar-events-container p {
  color: var(--text-light-white);
}

.diinco-calendar-events-container > ul > li {
  list-style: none;
  list-style-type: none;
  display: flex;
  flex-direction: row;
}

.diinco-time-display div.diinco-time-hour {
  width: 20%;
  padding: 8px;
}

[data-theme='light'] .diinco-time-display div.diinco-time {
  background-color: var(--card-color-yellow);
  color: var(--bg-color-alt);
}

.diinco-time-display div.diinco-time {
  background-color: var(--card-color-yellow);
  padding: 18px 5px;
  text-align: center;
  border-radius: 3px;
  cursor: pointer;
  color: #000000;
}

[data-theme='light'] .diinco-time-display div.diinco-time {
  background-color: var(--bg-dark-gray);
}

.diinco-time-display div.diinco-time img.diinco-time-check {
  height: 1rem;
}

.diinco-time-display div.diinco-time-body {
  width: 80%;
  padding: 3px 9px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin-bottom: 1px;
  color: white;
}

.diinco-time-display div.diinco-time-body p {
  margin: 0;
}

.diinco-time-display div.diinco-time-body span {
  margin: 0;
  margin-left: 0px;
}

.dinc .react-calendar {
  width: 100%;
  min-width: 200px;
  min-height: 200px;
  background: var(--hover-color);
  font-family: OpenSans;
  line-height: 1.5em;
  padding: 10px 20px;
}

[data-theme='light'] .dinc .react-calendar {
  background: var(--card-color-yellow);
}

.react-calendar--doubleView {
  width: 100%;
}

.react-calendar--doubleView .react-calendar__viewContainer {
  display: flex;
  margin: -0.5em;
  color: var(--text-white-color);
}

/* [data-theme='light'] .react-calendar--doubleView .react-calendar__viewContainer {
	color: black;
} */

.react-calendar--doubleView .react-calendar__viewContainer > * {
  width: 50%;
  margin: 0.5em;
}

.react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  /* color: var(--text-white-color); */
}

.react-calendar button {
  color: var(--text-white-color);
  padding: 10px;
  border: 0;
  outline: none;
  align-content: center;
  align-items: center;
  align-self: center;
}

.react-calendar button:enabled:hover {
  cursor: pointer;
}

.react-calendar__navigation {
  display: flex;
  height: 44px;
  justify-content: center;
  margin-bottom: 2em;
}

.react-calendar__navigation button {
  min-width: 44px;
  max-width: 400px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  background: none;
  font-family: OpenSans;
  font-size: 1.5rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: 1.6px;
}

.react-calendar__navigation__arrow {
  border-radius: 50%;
  background-color: var(--card-color-yellow) !important;
  width: 45px;
  height: 45px;
  color: var(--bg-dark-gray) !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

[data-theme='light'] .react-calendar__navigation__arrow {
  background-color: var(--background-button) !important;
  color: var(--card-color-yellow) !important;
}

.react-calendar__navigation__label__labelText {
  text-transform: capitalize;
  color: var(--card-color-yellow);
}

[data-theme='light'] .react-calendar__navigation__label__labelText {
  color: var(--bg-dark-gray);
}

.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
}

.react-calendar__navigation button[disabled] {
  background-color: #f0f0f0;
}

.react-calendar__month-view__weekdays {
  text-align: center;
  text-transform: uppercase;
  text-decoration: none !important;
  font-size: 0.75em;
}

.react-calendar__month-view__weekdays__weekday {
  padding: 0.5em;
  color: var(--card-color-yellow);
}

[data-theme='light'] .react-calendar__month-view__weekdays__weekday {
  color: var(--bg-dark-gray);
}

.react-calendar__month-view__weekNumbers .react-calendar__tile {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.75em;
  font-weight: bold;
  padding: calc(0.75em / 0.75) calc(0.5em / 0.75);
}

.react-calendar__month-view__days {
  min-height: 60px;
  font-size: 2rem;
}

.react-calendar__month-view__days__day {
  margin-bottom: 10px;
  min-height: 60px;
  font-size: 1.5rem;
  color: var(--card-color-yellow) !important;
  display: flex;
  flex-direction: column-reverse;
  justify-content: center;
  font-family: OpenSans;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: 1.6px;
  position: relative;
}

[data-theme='light'] .react-calendar__month-view__days__day {
  color: var(--bg-dark-gray) !important;
}

.react-calendar__month-view__days__day span {
  border-radius: 100%;
  height: 8px;
  width: 8px;
  background-color: var(--secondary-color);
  position: absolute;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  bottom: 5px;
}

[data-theme='light'] .react-calendar__month-view__days__day span {
  background-color: var(--background-button) !important;
}

.react-calendar__tile--active.react-calendar__month-view__days__day span {
  border-radius: 100%;
  height: 8px;
  width: 8px;
  background-color: var(--bg-dark-gray) !important;
  position: absolute;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  bottom: 5px;
}

[data-theme='light']
  .react-calendar__tile--active.react-calendar__month-view__days__day
  span {
  background-color: var(--text-color) !important;
}

.react-calendar__month-view__days__day--weekend {
  color: var(--text-white-color);
  margin-bottom: 10px;
  min-height: 60px;
  font-size: 1.5rem;
  font-family: OpenSans;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: 1.6px;
}

.react-calendar__month-view__days__day--neighboringMonth {
  color: var(--bg-color) !important;
  margin-bottom: 10px;
  min-height: 60px;
  font-size: 1.5rem;
  font-family: OpenSans;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: 1.6px;
}

.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
  padding: 2em 0.5em;
}

.react-calendar__tile {
  max-width: 100%;
  text-align: center;
  padding: 0.75em 0.5em;
  color: var(--text-white-color);
  background: none;
}

.react-calendar__tile:disabled {
  background-color: #f0f0f0;
}

.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
}

.react-calendar__tile--now {
  background: var(--secondary-color);
  color: var(--text-white-color);
}

[data-theme='light'] .react-calendar__tile--now {
  background: var(--text-color);
  color: var(--text-light-white);
}

.react-calendar__tile--hasActive {
  background: var(--secondary-color);
  color: var(--text-white-color);
}

[data-theme='light'] .react-calendar__tile--hasActive {
  background: var(--background-button);
  color: var(--text-light-white);
}

.react-calendar__tile--active {
  background: var(--card-color-yellow);
  color: var(--bg-dark-gray) !important;
}

[data-theme='light'] .react-calendar__tile--active {
  background: var(--background-button);
  color: var(--text-light-white) !important;
}

.react-calendar__tile--now {
  background: rgba(0, 0, 0, 0.8);
  color: var(--card-color-yellow) !important;
}

[data-theme='light'] .react-calendar__tile--now {
  background: var(--text-color);
  color: white !important;
}

.react-calendar__tile--now.react-calendar__tile--active {
  color: var(--bg-dark-gray) !important;
  background: var(--card-color-yellow) !important;
}

[data-theme='light'] .react-calendar__tile--now.react-calendar__tile--active {
  color: white !important;
  background: var(--background-button) !important;
}

.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  background: var(--card-color-yellow);
}

[data-theme='light'] .react-calendar__tile--active:enabled:hover,
[data-theme='light'] .react-calendar__tile--active:enabled:focus {
  background: var(--background-button);
}

.react-calendar--selectRange .react-calendar__tile--hover {
  background-color: #e6e6e6;
}

/* modal style */
.modal-title {
  color: var(--text-white-color);
}

.create-appointment {
  display: flex;
  justify-content: center;
  margin-top: 25px;
  margin-bottom: 15px;
  position: relative;
}

.buttons-container {
  margin-right: 10px;
}

.buttons-container img {
  margin-bottom: 10px;
  cursor: pointer;
}

.leo-buttons-container {
  right: 5px !important;
  bottom: 10px !important;
  width: 34px !important;
  height: 34px !important;
}

.react-calendar {
  background-color: var(--bg-dark-gray);
  margin-bottom: 20px;
  margin-top: 20px;
  padding-top: 20px;
  border-radius: 5px !important;
}

[data-theme='light'] .react-calendar {
  background-color: var(--card-color-yellow);
}

.react-calendar__month-view__days__day--neighboringMonth {
  color: white !important;
  opacity: 0.6;
}

.leo-card-calendar {
  position: relative;
  min-height: 70px;
}

.react-calendar__year-view__months__month {
  text-transform: capitalize !important;
}

.diinco-time-display {
  display: flex;
  margin-bottom: 5px;
}

.modal-title-calendar::first-letter {
  text-transform: capitalize;
}
