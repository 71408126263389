.pre_register_container {
  height: calc(100vh - 150px);
  display: flex;
  flex-direction: column;
  background-image: url('../assets/pexels-pixabay-276510@3x.jpg');
  /* background-position: cover; */
  /* Center the image */
  background-repeat: no-repeat;
  /* Do not repeat the image */
  background-size: cover;
  background-size: calc(100% +20px);
}

.card {
  margin-top: 50px;
  width: 50%;
  margin-top: 100px;
  margin-bottom: 100px;
  align-self: center;
  padding: 40px 20px;
  border-radius: 5px;
  border: solid 1px var(--bg-color);
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  margin-bottom: 50px;
}

img.register_diinco {
  width: 60%;
  margin: 10px;
  object-fit: cover !important;
}

h1.register_diinco {
  font-family: OpenSans;
  font-size: 45px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.36;
  letter-spacing: 1.35px;
  text-align: left;
  color: var(--text-color);
  align-self: center;
}

[data-theme='light'] .pre-register-card {
  box-shadow: 3px 10px 20px 0 rgba(0, 0, 0, 0.53) !important;
  background-color: rgba(255, 255, 255, 1) !important;
  border: none !important;
  margin-top: 10vh !important;
  max-width: 650px;
}

.pre-register-card {
  box-shadow: 3px 10px 20px 0 rgba(0, 0, 0, 0.53) !important;
  background-color: rgba(38, 38, 38, 0.9) !important;
  border: none !important;
  margin-top: 10vh !important;
  max-width: 650px;
}

.pre-button {
  min-width: 200px;
  color: var(--text-color) !important;
  border-color: var(--secondary-color);
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: color, background-color;
  transition-property: color, background-color;
}

.pre-button:hover {
  border-color: var(--secondary-color);
  background-color: var(--secondary-color) !important;
}

.texto-hover {
  visibility: hidden;
  transition: visibility 1s, opacity 1s;
  opacity: 0;
}

.button-hover:hover + .texto-hover {
  visibility: visible;
  opacity: 1;
}
