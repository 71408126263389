.cj-divider-component {
  height: 2px;
}

.cj-divider-component-dark {
  background-color: var(--bg-dark-gray);
}

.cj-divider-component-secondary {
  background-color: var(--secondary-color);
}

.cj-divider-component-primary {
  background-color: var(--primary-color);
}

.cj-divider-component-light {
  background-color: var(--border-input);
  height: 0.5px !important;
}
