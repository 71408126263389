.slider {
  z-index: 1;
  width: 100%;
  height: 100vh;
}

.slider .swiper-slide-active:after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 300px;
  background: linear-gradient(
    to top,
    rgba(0, 0, 0, 1) 0%,
    rgba(4, 4, 4, 0.83),
    rgba(84, 84, 84, 0) 100%
  );
}
