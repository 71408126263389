.diinco-footer-container {
  background-color: var(--bg-color);
  width: 100%;
  height: var(--footer-height);
  /* cambio provisional
   margin-top: 30px; */
  display: flex;
  align-content: space-between;
}

.diinco-footer-text:link,
.diinco-footer-text:visited {
  color: var(--primary-color) !important;
  background-color: transparent;
  text-decoration: none;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.37;
  letter-spacing: 0.9px;
  font-size: 15px;
  text-transform: none !important;
}

.diinco-footer-text:hover {
  color: var(--primary-color) !important;
  background-color: transparent;
}

.diinco-footer-icon {
  height: 15px;
  margin-right: 5px;
  margin-bottom: 5px;
}

.diinco-footer-item-container {
  margin-right: 5px;
}

.links-container-footer {
  display: flex;
  justify-content: center;
  gap: 20px;
}
