.diinco-tab-container {
  width: 100%;
  height: auto;
  min-height: 500px;
}

.diinco-ProfessionalDashboard-banner {
  height: 400px;
  width: 100%;
  object-fit: cover;
}

.diinco-ProfessionalDashboard-container {
  margin-top: 20px;
  padding-bottom: 20px;
  margin-bottom: 30px;
}

.diinco-profile {
  position: relative;
}

.diinco-profile-container {
  background-color: var(--primary-color-alt);
  z-index: 10;
  width: 80%;
  top: -270px;
  height: calc(100% + 220px);
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 0px 10px;
  padding-top: 50px;
  min-height: 800px;
  border-radius: 5px;
}

[data-theme='light'] .diinco-profile-container {
  background-color: #aeaeae;
}

.diinco-profile-container img {
  height: 200px;
  width: 200px;
  object-fit: cover;
  border-radius: 100%;
}

.diinco-profile-container h2 {
  margin-top: 20px;
  color: var(--text-white-color);
  letter-spacing: 1.6px;
  font-size: 2rem;
  margin-bottom: 10px;
}

.diinco-profile-container h3 {
  margin: 0px;
  width: 100%;
  color: var(--text-white-color);
  font-size: 1rem;
  letter-spacing: 1px;
}

.diinco-badge-container {
  display: flex;
  flex-direction: row;
  column-gap: 10px;
  row-gap: 10px;
  justify-content: center;
  margin: 30px 20px;
  overflow: hidden;
  flex-wrap: wrap;
}

.diinco-badge {
  min-width: 30px;
  min-height: 30px;
  max-height: 30px;
  max-width: 30px;
}

.diinco-plan-update-container {
  width: 100%;
  bottom: 20px;
  position: absolute;
  display: flex;
  flex-direction: column;
}

.diinco-plan-update-container a {
  text-transform: uppercase;
  color: var(--golden-color);
}

.diinco-plan-update-container a:hover {
  font-weight: bold;
  text-decoration: none;
}

.diinco-plan-update-container div.diinco-plan-update-divider {
  height: 1px;
  width: 80%;
  background-color: var(--golden-color);
  align-self: center;
  margin: 10px 0px;
}

.diinco-sphere-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  column-gap: 10px;
  row-gap: 10px;
}

.diinco-sphere-container span {
  height: 15px;
  width: 15px;
  border-radius: 100%;
  background-color: aliceblue;
}

.diinco-sphere-container span.diinco-sphere-1 {
  background-color: var(--primary-color);
}

.diinco-sphere-container span.diinco-sphere-2 {
  background-color: var(--hover-color);
}

.diinco-sphere-container span.diinco-sphere-3 {
  background-color: var(--secondary-color);
}
