.fofo-accordion-title {
  color: var(--bg-dark-gray) !important;
  font-weight: 600;
}

.fofo-more-benefits {
  height: min-content;
  margin-left: 20px;
  text-decoration: underline;
}

.fofo-plan-container {
  margin-bottom: 30px;
}

.uk-accordion li.uk-open .uk-accordion-title:before {
  margin: 15px;
  /* content: url('data:image/svg+xml;base64,PHN2ZyBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGZpbGwtcnVsZT0iZXZlbm9kZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIgc3Ryb2tlLW1pdGVybGltaXQ9IjIiIHZpZXdCb3g9IjAgMCAyNCAyNCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJtMjEgNC4wMDljMC0uNDc4LS4zNzktMS0xLTFoLTE2Yy0uNjIgMC0xIC41MTktMSAxdjE2YzAgLjYyMS41MiAxIDEgMWgxNmMuNDc4IDAgMS0uMzc5IDEtMXptLTE2LjUuNWgxNXYxNWgtMTV6bTIuNDQ5IDcuODgyIDMuODUxIDMuNDNjLjE0Mi4xMjguMzIxLjE5LjQ5OS4xOS4yMDIgMCAuNDA1LS4wODEuNTUyLS4yNDJsNS45NTMtNi41MDljLjEzMS0uMTQzLjE5Ni0uMzIzLjE5Ni0uNTAyIDAtLjQxLS4zMzEtLjc0Ny0uNzQ4LS43NDctLjIwNCAwLS40MDUuMDgyLS41NTQuMjQzbC01LjQ1MyA1Ljk2Mi0zLjI5OC0yLjkzOGMtLjE0NC0uMTI3LS4zMjEtLjE5LS40OTktLjE5LS40MTUgMC0uNzQ4LjMzNS0uNzQ4Ljc0NiAwIC4yMDUuMDg0LjQwOS4yNDkuNTU3eiIgZmlsbC1ydWxlPSJub256ZXJvIi8+PC9zdmc+'); */
}

.uk-accordion li .uk-accordion-title:before {
  margin: 15px;
  /* content: url('data:image/svg+xml;base64,PHN2ZyBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGZpbGwtcnVsZT0iZXZlbm9kZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIgc3Ryb2tlLW1pdGVybGltaXQ9IjIiIHZpZXdCb3g9IjAgMCAyNCAyNCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJtMTEuOTk4IDIxLjk5NWM1LjUxNyAwIDkuOTk3LTQuNDggOS45OTctOS45OTcgMC01LjUxOC00LjQ4LTkuOTk4LTkuOTk3LTkuOTk4LTUuNTE4IDAtOS45OTggNC40OC05Ljk5OCA5Ljk5OCAwIDUuNTE3IDQuNDggOS45OTcgOS45OTggOS45OTd6bTAtMS41Yy00LjY5IDAtOC40OTgtMy44MDctOC40OTgtOC40OTdzMy44MDgtOC40OTggOC40OTgtOC40OTggOC40OTcgMy44MDggOC40OTcgOC40OTgtMy44MDcgOC40OTctOC40OTcgOC40OTd6bTQuODQ1LTYuNzExYy4xMDguMTQxLjE1Ny4zLjE1Ny40NTYgMCAuMzg5LS4zMDYuNzU1LS43NDkuNzU1aC04LjUwMWMtLjQ0NSAwLS43NS0uMzY3LS43NS0uNzU1IDAtLjE1Ny4wNS0uMzE2LjE1OS0uNDU3IDEuMjAzLTEuNTU0IDMuMjUyLTQuMTk5IDQuMjU4LTUuNDk4LjE0Mi0uMTg0LjM2LS4yOS41OTItLjI5LjIzIDAgLjQ0OS4xMDcuNTkxLjI5MXptLTcuNTY0LS4yODloNS40NDZsLTIuNzE4LTMuNTIyeiIgZmlsbC1ydWxlPSJub256ZXJvIi8+PC9zdmc+'); */
}

/* ----------------------------------------------- */
.dc-profile-form-input-margin {
  margin-left: 250px !important;
  margin-right: 250px !important;
}

.dc-profile-form-title {
  color: #cbcbcb;
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: 0.8px;
  margin-bottom: 20px;
}

.dc-button-yellow {
  border-radius: 5px;
  background-color: var(--secondary-color);
  padding: 15px 20px 14px 21px;
  border: none;
  font-size: 20px;
  font-weight: 200;
  font-stretch: normal;
  line-height: 1.2;
  letter-spacing: 0.8px;
  color: #262626;
}

.dc-link-gray {
  color: #cbcbcb;
  text-decoration: underline;
  font-size: 18px;
  font-weight: 200;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: 0.8px;
}

.dc-link-gray:hover {
  color: #cbcbcb;
  opacity: 0.5;
}

.diinco-profile-form-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 2px;
  border-width: 2px;
  border-color: #707070;
  border-style: solid;
  color: #cbcbcb;
  outline: none;
  cursor: pointer;
  height: 300px;
  width: 100%;
}
.diinco-profile-form-container > div {
  width: 100%;
}
img.diinco-profile-form-image {
  width: 100%;
  max-height: 300px;
  object-fit: cover;
}

.diinco-profile-banner-container {
  position: relative;
}
.diinco-profile-image-container {
  position: absolute;
  left: 20px;
  bottom: -100px;
  height: 200px;
  width: 200px;
  background-color: #262626;
  border-radius: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.diinco-profile-image-container > div {
  width: 100%;
  height: 100%;
  border-radius: 100%;
}
.diinco-profile-image-style-image {
  width: 100% !important;
  height: 100% !important;
  object-fit: cover;
  border-radius: 100%;
}
.diinco-profile-form-image {
  width: 100% !important;
  height: 100% !important;
  object-fit: cover;
}

.input-photo-leo {
  display: flex;
  align-items: center;
}

.photo-client {
  border-radius: 50%;
  border: solid 2px #707070;
  height: 120px;
  width: 120px;
  margin-right: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.photo-client .initials {
  color: var(--secondary-color);
  font-family: OpenSans;
  font-size: 53px;
}

.photo-client img {
  width: 120px;
  height: 120px;
  object-fit: cover;
  border-radius: 50%;
}
