.dc-report-card-title {
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: 0.7px;
}

.dc-report-card-text {
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: 0.72px;
  text-align: left;
}

.dc-report-card-light {
  background-color: #5d6163;
}

.dc-report-card:hover {
  background-color: var(--hover-color);
  cursor: pointer;
}

.dc-report-divider {
  border-bottom: 2px solid var(--hover-color);
}

.dc-img-cover {
  width: 40%;
  height: 55%;
  object-fit: cover;
}
