.cj-checkbox-input {
  border-radius: 5px;
  transition: none;
  border: 1px solid #ccc !important;
  margin-right: 6px;
}

.cj-checkbox-input:checked {
  color: var(--primary-color);
  background-color: var(--primary-color);
  background-image: none;
}

.cj-checkbox-input:checked:focus {
  color: var(--primary-color);
  background-color: var(--primary-color);
  background-image: none;
}

.cj-checkbox-input-container {
  color: #ffff;
  font-weight: 300;
}
.cj-checkbox-input-container label {
  color: var(--hover-color-alt);
}
.cj-input-margin-bottom-checkbox {
  margin-bottom: 33px;
}

.cj-error-message {
  font-size: 12px;
  color: var(--red-color);
  opacity: 1;
  font-weight: 400;
}

.cj-error-message span {
  margin-right: 3px;
}