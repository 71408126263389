div#loader {
  background: linear-gradient(
    -45deg,
    rgba(236, 102, 47, 1),
    rgba(250, 188, 79, 1)
  );
  background-size: 400% 400%;
  animation: loader-animation 2s ease infinite;
  top: 0 !important;
  position: fixed !important;
  width: 100%;
  z-index: 1001;
  min-height: 5px;
}
.diinco-utils-separator {
  margin-bottom: 100px;
}

.diinco-felxible-container {
  display: flex;
  margin: 0px;
  flex-direction: row;
}

div#notification-container {
  position: fixed;
  width: 20%;
  z-index: 5000;
  bottom: 10px;
  left: 10px;
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  justify-content: center;
  align-content: center;
  text-align: center;
}

@keyframes loader-animation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
div.diinco-abosolute-notification-container {
  position: fixed !important;
}
.diinco-abosolute-notification-container div {
  position: fixed;
  background-color: rgba(38, 38, 38, 0.9);
  width: 300px;
  height: 120px;
  z-index: 2500 !important;
  top: 0px;
  right: 0px;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  top: 0;
  bottom: 0;
  margin-top: auto;
  margin-bottom: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
}
.diinco-abosolute-notification-container div p {
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: 1.2px;
  color: white;
  text-transform: none;
}
