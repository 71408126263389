.cj-project-dashboard-users {
  width: fit-content;
  max-width: 300px;
  padding: 30px;
  background-color: rgba(var(--bg-color-rgb), 0.9);
  border-radius: 5px;
}

.customModalCRM-dark-plan {
  background-color: rgba(0, 0, 0, 0.9) !important;
  color: #ffffff;
  padding-top: 10px !important;
  padding-left: 30px !important;
  padding-right: 30px !important;
  padding-bottom: 30px !important;
  min-width: 30%;
  border-radius: 5px;
}
