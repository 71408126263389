.dc-project-form-title {
  color: var(--text-white-color);
  font-size: 50px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.36;
  letter-spacing: 1.5px;
}

.dc-dark-input {
  background-color: var(--bg-color);
}

.diinco-project-form-dropzone-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-width: 2px;
  border-radius: 2px;
  border-color: #707070;
  border-style: solid;
  background-color: transparent;
  color: #cbcbcb;
  outline: none;
  height: 215px;
  cursor: pointer;
}

.diinco-project-form-dropzone-image {
  width: 100% !important;
  height: 100% !important;
  object-fit: cover;
}

.diinco-thumbs-container-project-form {
  width: 100%;
  height: 100px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  column-gap: 10px;
  overflow-x: auto;
  overflow-y: hidden;
  margin-bottom: 10px;
  white-space: nowrap;
}

.diinco-thumbs-style-project-form {
  width: 70px !important;
  height: 70px !important;
  aspect-ratio: 1 !important;
  object-fit: cover;
  position: relative;
}

.diinco-thumbs-delete-project-form {
  position: absolute;
  height: 20px;
  width: 20px;
  cursor: pointer;
  top: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  background-color: wheat;
}

.cj-tags-container {
  height: 160px !important;
}
