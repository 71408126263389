.oski-prospect-titles-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.oski-edit-icon {
  width: 30px;
  height: 30px;
  cursor: pointer;
}

.oski-prospect-title {
  font-family: OpenSans;
  font-size: 20px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.36;
  letter-spacing: 0.75px;
  text-align: left;
  color: var(--switch-title-color);
}

.oski-prospect-desc {
  font-family: OpenSans;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.35;
  letter-spacing: 0.6px;
  text-align: left;
  color: var(--text-white-color);
  margin-top: 20px;
  text-align: justify;
}

.oski-user-container img {
  border-radius: 50%;
  width: 75px;
  height: 75px;
  margin-right: 30px;
  object-fit: cover;
}

.oski-user-name {
  font-family: OpenSans;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.35;
  letter-spacing: 0.6px;
  text-align: left;
  color: var(--switch-title-color);
}

.oski-user-role {
  font-family: OpenSans;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.35;
  letter-spacing: 0.6px;
  text-align: left;
  color: var(--text-white-color);
}

.oski-prospect-lastDate {
  font-family: OpenSans;
  font-size: 20px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.36;
  letter-spacing: 0.75px;
  text-align: left;
  color: var(--switch-title-color);
  margin-right: 16px;
}

.oski-prospect-history {
  font-family: OpenSans;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.35;
  letter-spacing: 0.6px;
  text-align: left;
  color: var(--text-white-color);
  text-decoration: underline;
  margin-top: 20px;
  margin-bottom: 50px;
  cursor: pointer;
}

.oski-prospect-date {
  font-family: OpenSans;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.35;
  letter-spacing: 0.6px;
  text-align: left;
  color: var(--text-white-color);
  cursor: pointer;
}

.oski-prospect-date:hover {
  text-decoration: underline;
}

.oski-prospect-note {
  font-family: OpenSans;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.35;
  letter-spacing: 0.6px;
  text-align: left;
  color: var(--text-white-color);
  margin-top: 20px;
  margin-bottom: 30px;
  text-align: justify;
}

.oski-proposal-note {
  font-family: OpenSans;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.35;
  letter-spacing: 0.6px;
  text-align: left;
  color: var(--text-invert-color);
  margin-top: 20px;
  margin-bottom: 30px;
  text-align: justify;
}

.customModalPhase {
  background-color: rgba(var(--bg-color-rgb), 0.9);
  border-radius: 5px;
  width: 40%;
  opacity: 1;
}

.oski-prospect-dates {
  margin-top: 15px;
  font-family: OpenSans;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.35;
  letter-spacing: 0.6px;
  text-align: left;
  color: var(--text-white-color);
}

.oski-prospect-title-date {
  font-family: OpenSans;
  font-size: 18px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.36;
  letter-spacing: 0.75px;
  color: var(--switch-title-color);
  display: flex;
  justify-content: space-between;
}

[data-theme='light'] .oski-prospect-title-date {
  color: var(--text-color);
}

.prospect-modal-edit {
  width: 400px;
  padding: 30px;
  opacity: 1;
}

.oski-flex-end {
  justify-content: flex-end;
}

.oski-align-center {
  align-items: center;
}

.oski-pointer {
  cursor: pointer;
}

.oski-pointer:hover {
  filter: invert(43%) sepia(5%) saturate(100%) hue-rotate(149deg)
    brightness(97%) contrast(94%);
}

.leo-button-black {
  background-color: var(--bg-dark-gray);
  border-radius: 5px;
  padding: 11px 40px 10px 41px;
  min-width: 100px;
  color: var(--border-color);
  border: none;
}

.oski-document-text {
  font-family: OpenSans;
  font-size: 14px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.36;
  letter-spacing: 0.75px;
  color: var(--switch-title-color);
}

.oski-note-imagesContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.oski-note-image {
  width: 216px;
  height: 171px;
  object-fit: cover;
  border-radius: 5px;
  margin-bottom: 15px;
  cursor: pointer;
}

.customModalGalleryProject {
  background-color: rgb(var(--bg-color-rgb));
  border-radius: 5px;
  padding: 35px 45px;
  width: 100%;
}

.oski-icons-dark {
  filter: invert(100%) sepia(0%) saturate(5326%) hue-rotate(352deg)
    brightness(130%) contrast(114%);
}

.oski-file-light {
  filter: invert(100%) sepia(0%) saturate(5326%) hue-rotate(352deg)
    brightness(130%) contrast(114%);
}

.oski-icons-light {
  filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(21deg)
    brightness(97%) contrast(103%);
}

.oski-documents-dropdown {
  max-height: fit-content;
}

.calendarModal {
  min-width: 300px;
  padding: 30px;
  background-color: rgba(var(--bg-color-rgb2), 0.9);
  border-radius: 5px;
}

[data-theme='light'] .calendarModal {
  min-width: 300px;
  padding: 30px;
  background-color: rgba(var(--bg-color-rgb2), 1);
  border-radius: 5px;
}

.oski-documents-row {
  color: var(--text-color) !important;
  width: 100%;
  cursor: pointer;
  display: flex;
  text-transform: capitalize;
  text-decoration: none;
  transition: ease-in-out 0.3s;
  font-size: 14px;
}

.oski-documents-row:hover {
  background-color: #757575;
  text-decoration: none;
  transition: ease-in-out 0.3s;
}

.proposal-container {
  border: solid 1px #ea6732;
  padding: 27px 57px 0px;
  border-radius: 5px;
}

.proposal-note {
  background-color: #808080;
  padding: 35px 35px 15px;
  border-radius: 5px;
  margin-bottom: 20px;
}

[data-theme='light'] .proposal-note {
  background-color: rgba(174, 174, 174, 0.5);
}
