.diinco-chat-absolute-container {
  position: fixed;
  bottom: 0px;
  right: 0px;
  display: flex;
  flex-direction: row-reverse;
  padding: 0px 40px;
  column-gap: 20px;
  align-items: flex-end;
  z-index: 5000;
  pointer-events: none;
}
.diinco-chat-container {
  bottom: 0px;
  right: 20px;
  width: 280px;
  min-height: 35px;
  background-color: var(--secondary-color);
  display: flex;
  flex-direction: column;
  position: relative;
  pointer-events: all;
}
.diinco-chat-container.active {
  height: 350px;
  background-color: var(--text-gray-color);
}
.diinco-chat-container.active.main {
  background-color: var(--bg-dark-color);
}
.diinco-chat-container.main {
  background-color: var(--bg-dark-color);
}
.diinco-chat-title {
  cursor: pointer;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-content: center;
  align-items: center;
  justify-content: flex-start;
  position: relative;
}
.diinco-chat-title.main {
  justify-content: center;
}
.diinco-chat-title h2 {
  margin: 10px;
  text-transform: uppercase;
  font-size: 1rem;
  font-weight: bold;
  color: var(--bg-color);
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-right: 12px;
  overflow: hidden;
}
.diinco-chat-title span {
  position: absolute;
  right: 10px;
  top: 10px;
}
.diinco-chat-title img {
  object-fit: cover;
  height: 28px;
  width: 28px;
  margin: 0px 5px 0px 10px;
  border-radius: 100%;
}

.diinco-chat-title.main h2 {
  color: var(--text-gray-color);
}
.diinco-chat-title.active {
  background-color: var(--secondary-color);
}
.diinco-chat-title.active.main {
  background-color: var(--text-gray-color);
}

.diinco-chat-title.active h2 {
  color: var(--bg-dark-color);
}
.diinco-chat-title.active.main h2 {
  color: var(--bg-dark-color);
}

.diinco-chat {
  height: 300px;
  padding: 10px;
  overflow-y: auto;
  position: relative;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
}

.diinco-chat span.load-more {
  text-align: center;
  padding: 5px;
  font-size: 1.2rem;
  color: var(--text-white-color);
  border: 1px solid var(--text-white-color);
  cursor: pointer;
  margin-bottom: 10px;
}

.diinco-chat-input {
  position: relative;
  padding: 10px;
}

.diinco-chat-input input {
  padding: 5px 10px;
  width: 90%;
  border-radius: 5px;
  border-color: transparent;
  border-width: 0px;
  border-style: none;
}
.diinco-chat-input input:focus {
  outline: none;
}
.diinco-chat-message {
  min-width: none;
  max-width: 80%;
  background-color: var(--secondary-color);
  padding: 5px 10px;
  margin: 5px;
  font-size: 0.8rem;
  border-radius: 20px;
  align-self: flex-start;
  display: flex;
  flex-direction: column-reverse;
  position: relative;
  margin-bottom: 15px;
  word-wrap: break-word;
  margin-left: 25px;
}
.diinco-chat-message.self {
  position: relative;
  align-self: flex-end;
  text-align: end;
  flex-direction: column-reverse;
  background-color: var(--text-white-color);
  word-wrap: break-word;
  max-width: 80%;
  margin-right: 25px;
  margin-bottom: 15px;
}

.diinco-chat-message span {
  position: absolute;
  bottom: -15px;
  right: 0px;
  padding: 0px 10px;
  display: block;
  font-size: 0.6rem;
  width: 80%;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.diinco-chat-message img.diinco-chat-user-image {
  width: 20px;
  height: 20px;
  border-radius: 100%;
  object-fit: cover;
  position: absolute;
  top: 5;
  left: -25px;
}
.diinco-chat-message.self img.diinco-chat-user-image {
  left: auto;
  right: -25px;
}

.diinco-chats-chat-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  margin: 10px 2px;
  padding: 5px 0px;
  font-size: 1rem;
  color: var(--text-white-color);
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  min-height: 30px;
}
span.diinco-chats-chat-remaining-counter {
  background-color: var(--bg-color);
  position: absolute;
  right: 10px;
  padding: 0px 5px;
  border-radius: 100%;
}
div.diinco-no-seen-messages {
  background-color: var(--primary-color);
  text-align: center;
  font-size: 0.8rem;
  font-style: italic;
  margin-top: 10px;
  margin-bottom: 10px;
}
div.diinco-chat-unseen-indicator {
  height: 1rem;
  width: 1rem;
  border-radius: 100%;
  text-align: center;
  font-size: 0.7rem;
  padding: 0 0;
  color: var(--text-white-color);
  background-color: var(--primary-color);
}

.diinco-chats-chat-container img {
  height: 40px;
  width: 40px;
  border-radius: 100%;
  margin: 0px 15px 0px 10px;
  object-fit: cover;
}
.diinco-chats-chat-container.connected img {
  border-width: 3px;
  border-style: solid;
  border-color: green;
}
.diinco-chats-chat-container.disconnected img {
  border-width: 3px;
  border-style: solid;
  border-color: red;
}

.diinco-chat-option-menu-container {
  opacity: 0.9;
  border-radius: 5px;
  display: flex;
  padding: 5px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-width: 200px;
  max-width: 300px;
  align-content: center;
  background-color: var(--bg-dark-color);
}
.diinco-chat-option-menu-container div {
  color: var(--text-white-color);
  width: 100%;
  padding: 5px;
}
.diinco-chat-option-menu-container div:hover {
  background-color: var(--text-white-color);
  color: var(--bg-dark-color);
  width: 100%;
}
