li {
  list-style: none;
}

a {
  text-decoration: none;
  color: var(--text-color) !important;
  text-transform: uppercase;
}

.custom-navbar {
  /* Aqui falta esperar a ver como es el tema oscuro */
  /* background: linear-gradient(
  180deg,
  rgba(52, 52, 52, 1) 0%,
  rgba(103, 103, 103, 0) 100%
) !important; */
  background-color: var(--bg-color) !important;
  top: 0 !important;
  /* padding-top: 10px; */
  width: 100%;
  z-index: 100;
  margin: 0px;
  position: relative;
  display: flex;
}

.custom-navbar.home {
  background-color: transparent !important;
  position: absolute;
}

.custom-navbar.home .navbar-logo img {
  display: none;
}

.navbar-logo {
  height: 60px;
  cursor: pointer;
}

.uk-navbar-nav > li > button {
  /* 1 */
  display: flex;
  justify-content: center;
  align-items: center;
  /* 2 */
  column-gap: 0.25em;
  /* 3 */
  box-sizing: border-box;
  min-height: 80px;
  padding: 0 15px;
  /* 4 */
  font-size: 0.875rem;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
      "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
      "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  /* 5 */
  text-transform: uppercase;
  text-decoration: none;
  border: none;
  background: none;
  color: var(--text-color);
}

.uk-navbar-nav > li:hover > button {
  cursor: pointer;
  color: var(--hover-color);
}

.uk-navbar-nav > li > a {
  cursor: pointer;
  color: var(--text-color) !important;
}

.uk-navbar-nav > li:hover > a {
  color: var(--hover-color) !important;
}

.uk-navbar-nav > li > a > img,
.uk-navbar-nav > li > a > div > img {
  /* 1 */
  display: flex;
  justify-content: center;
  align-items: center;
  /* 2 */
  column-gap: 0.25em;
  /* 3 */
  box-sizing: border-box;
  height: 20px;
  width: 20px;
  aspect-ratio: 1;
  border-radius: 100%;
  border-color: #fff;
  border-width: 1px;
  -webkit-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
}

.uk-navbar-nav > li > a > img:hover,
.uk-navbar-nav > li > a > div > img:hover {
  color: var(--hover-color);
  -webkit-transform: scale(1.2);
  transform: scale(1.2);
}

.dropdownLang {
  height: 33px !important;
  min-width: 60px !important;
  width: 60px !important;
  border-radius: 5px !important;
}

.dropdownLang:hover {
  border-radius: 5px !important;
}

.dropdownLang::before {
  border-radius: 5px !important;
}

.first-option {
  border-radius: 5px !important;
}

.leo-icon:hover {
  filter: invert(44%) sepia(7%) saturate(139%) hue-rotate(149deg)
      brightness(94%) contrast(90%) !important;
}

.dc-dropdown-notifications {
  width: 472px !important;
  border-radius: 5px;
  background-color: black;
  padding: 26px 18px;

  display: flex;
  flex-direction: column;
  row-gap: 10px;
}

[data-theme="light"] .dc-dropdown-notifications {
  background-color: white;
  box-shadow: 0px 0px 18px -7px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: 0px 0px 18px -7px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 18px -7px rgba(0, 0, 0, 0.75);
}

.dc-color-aq {
  height: 0px;
}

.container-avatar-nav {
  background-color: transparent;
  padding: 0px;
  border: none;
  cursor: pointer;
}

.dropdown-avatar {
  padding: 1px;
  border-radius: 5px;
  border: var(--secondary-color) 1px solid;
}

.notifications-logo {
  width: 20px;
  height: 28px;
}

.container-orange-dot {
  position: relative;
}

.orange-dot {
  position: absolute;
  top: -7px;
  right: -7px;
  background: #ea6732;
  padding: 6px;
  box-sizing: border-box;
  border-radius: 100%;
}
::-webkit-calendar-picker-indicator {
  filter: invert(1);
}