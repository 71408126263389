.diinco-background-image {
  height: 100vh;
  width: 100%;
  position: absolute;
  z-index: 0;
  top: 80;
  background-image: url('../assets/pexels-pixabay-276510@3x.jpg');
  background-size: cover;
}
.diinco-login-view-container {
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.diinco-login-view-title {
  z-index: 2;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.diinco-login-view-title h1 {
  color: var(--text-light-white);
  font-size: 3.5rem;
  text-align: center;
  text-shadow: 3px 10px 6px rgba(0, 0, 0, 0.16);
}

.diinco-alpha-card {
  box-shadow: 3px 10px 20px 0 rgba(0, 0, 0, 0.53);
  background-color: rgba(var(--bg-color-rgb), 0.9);
}
.diinco-alpha-card h1 {
  font-size: xx-large;
  /* font-weight: lighter; */
  text-align: center;
}
.diinco-alpha-card button {
  width: 100%;
}

.diinco-alpha-card span.forgot-password {
  color: #fbb857;
  align-self: center;
  margin: 10px;
  cursor: pointer;
}
.diinco-alpha-card span.register-now {
  color: #f36f39;
  align-self: center;
  margin: 10px;
  cursor: pointer;
}
.diinco-centered {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-content: center;
}

.dc-code-verification {
  background: var(--bg-dark-color);
  padding: 30px 70px 30px;
  color: var(--text-white-color);
  text-align: center;
  border-radius: 5px;
}

.dc-code-verification-title{
	color: var(--text-light-white);
	font-size: 18px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.22;
	letter-spacing: 0.45px;
	text-transform: uppercase;
}

.dc-code-verification-placeholder{
	color: var(--text-light-white);
	font-size: 14px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.27;
	letter-spacing: 0.28px;
	opacity: 0.8;
	margin-bottom: 5px;
}

.leo-login-title {
  color: var(--text-color) !important;
}
