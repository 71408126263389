/* [data-theme='light'] .cj-hover-reports:hover > *{
    color: white !important;
}

[data-theme='light'] .cj-hover-reports > *{
    color: #262626 !important;
}

[data-theme='dark'] .cj-hover-reports > *{
    color: white !important;
} */

[data-theme='light'] .cj-hover-reports:hover .cj-green {
  color: #00ff40 !important;
}

[data-theme='light'] .cj-hover-reports .cj-green {
  color: #3abd5a !important;
}
