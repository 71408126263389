.cj-height-dropzone-f {
  height: 514px !important;
}

.cj-height-dropzone-f-payment {
  height: 440px !important;
}

.cj-height-dropzone-payment {
  height: 500px !important;
}
