/* BOARD STYLE */
.board-container {
  display: flex;
  width: fit-content;
  /* background-color: aquamarine; */
}

/* COLUMN STYLE */
.column-container {
  background-color: var(--hover-color);
  min-width: 250px;
  min-height: 550px;
  width: 250px;
  margin: 10px;
  padding-top: 10px;
  display: flex;
  flex-direction: column;
}

.orange-column-container {
  background-color: var(--primary-color);
  min-width: 250px;
  min-height: 550px;
  width: 250px;
  margin: 10px;
  padding-top: 10px;
  display: flex;
  flex-direction: column;
}

.yellow-column-container {
  background-color: var(--secondary-color);
  min-width: 250px;
  min-height: 550px;
  width: 250px;
  margin: 10px;
  padding-top: 10px;
  display: flex;
  flex-direction: column;
}

.column-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.orange-column-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.column-title {
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  font-size: 18px;
  font-weight: 550;
  margin-bottom: 10px;

  width: 100%;
  margin-left: 10px;
  padding-left: 25px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.rename-input {
  width: 100%;
  margin-left: 10px;
  margin-right: 5px;
  background-color: transparent;
  border-style: none;
  border-bottom: solid 2px var(--primary-color);
  color: var(--text-gray-color);
  opacity: 0.5;
  text-transform: uppercase;
  text-align: center;
  font-size: 18px;
  font-weight: 550;
}

.rename-input:focus {
  border-bottom: solid 2px var(--secondary-color);
}

.column-more {
  color: var(--text-white-color) !important;
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  margin-right: 10px;
}

[data-theme='light'] .column-more {
  color: #ffffff !important;
}

.column-more:hover {
  color: var(--secondary-color);
}

.uk-dropdown {
  max-height: 102px;
  background-color: rgba(var(--bg-color-rgb2), 0.9);
}

[data-theme='light'] .uk-dropdown {
  background-color: rgba(var(--bg-color-rgb2), 1);
  opacity: 1;
}

.dropdown-option {
  /* background-color: gold; */
  margin: 0px !important;
  padding-left: 15px;
  padding-top: 5px;
  padding-bottom: 5px;
  cursor: pointer;
}

.dropdown-option:hover {
  background-color: var(--hover-drop);
  color: var(--text-color);
}

.last-option {
  margin: 0px !important;
  border-radius: 0px 0px 5px 5px;
  /* padding-left: 0px;
    padding-top: 0px;
    padding-bottom: 0px; */
}

.last-option:hover {
  background-color: var(--hover-drop);
  color: var(--text-color);
}

.colors-container {
  /* background-color: deepskyblue; */
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 8px;
  padding-bottom: 8px;
  display: flex;
  justify-content: space-between;
}

.color-option {
  border-radius: 5px;
  width: 18px;
  height: 18px;
  display: flex;
}

.color-option:hover {
  border: solid 2px var(--text-white-color);
}

.color-1 {
  background-color: #c8dc00;
}
.color-2 {
  background-color: #6bb47a;
}
.color-3 {
  background-color: #ff0000;
}
.color-4 {
  background-color: #77ffe3;
}
.color-5 {
  background-color: #ff00b2;
}

.first-option {
  border-radius: 5px 5px 0px 0px;
}

.task-list-container {
  padding: 10px;
  flex-grow: 1;
  min-height: 100px;
}

.orange-task-list-container {
  padding: 10px;
  flex-grow: 1;
  min-height: 100px;
  background-color: var(--primary-color) !important;
}

.yellow-task-list-container {
  padding: 10px;
  flex-grow: 1;
  min-height: 100px;
  background-color: var(--secondary-color) !important;
}

/* TASK STYLE */
.task-container {
  background-color: var(--card-color-yellow) !important;
  color: var(--bg-dark-gray);
  padding: 5px;
  margin-bottom: 10px;
  border-radius: 5px;
}

[data-theme='light'] .task-container {
  background-color: #ffffff !important;
  color: var(--bg-dark-gray);
}

/* CARD STYLE */
.card-container {
  padding: 5px;
  text-transform: uppercase;
}

.prospect-container {
  color: var(--bg-dark-gray);
  font-size: 14px;
  line-height: 18px;
  width: 100%;
}

.prospect-title {
  font-weight: bold;
  font-size: 16px;
  line-height: 26px;
  text-overflow: ellipsis;
  overflow: hidden;
}

.prospect-description {
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.prospect-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 25px;
}

.prospect-info-icons {
  min-height: 25px;
  max-height: 25px;
  display: inline-flex;
}

.prospect-info-icons img {
  margin-right: 5px;
}

.date-icon {
  width: 16px;
}

.prospect-photos-container {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  width: 100%;
}

.user-photo {
  min-width: 25px;
  min-height: 25px;
  max-width: 25px;
  max-height: 25px;
  object-fit: cover;
  margin-left: 5px;
}

/* CARD STYLE MODAL */
.customModal {
  background-color: var(--bg-dark-color) !important;
  color: var(--text-gray-color);
  padding: 30px !important;
  min-width: 30%;
  border-radius: 5px;
}

.customModalCRM-light {
  background-color: rgba(250, 250, 250, 1) !important;
  color: #262626;
  padding: 30px !important;
  min-width: 30%;
  border-radius: 5px;
}

.customModalCRM-dark {
  background-color: rgba(0, 0, 0, 0.9) !important;
  color: #ffffff;
  padding: 30px !important;
  min-width: 30%;
  border-radius: 5px;
}

.customModalDelete {
  background-color: var(--card-color-yellow) !important;
  color: black;
  padding: 30px !important;
  min-width: 15%;
  border-radius: 5px;
}

.modal-delete-container {
  display: flex;
  justify-content: center;
}

.modal-delete-button {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.modal-title {
  color: var(--text-gray-color);
  text-transform: uppercase;
  font-weight: 650;
  margin-bottom: 20px;
}

.modal-description {
  margin-top: 5px;
}

.appointment-container {
  display: flex;
  align-items: center;
  margin-bottom: 2px;
}

.appointment-icon {
  width: 16px;
}

.appointment-text {
  margin-left: 10px;
}

.user-modal {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.user-photo-modal {
  min-width: 50px;
  min-height: 50px;
  max-width: 50px;
  max-height: 50px;
  object-fit: cover;
}

.user-modal-container {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-left: 15px;
  width: 100%;
}

.last-update {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.create-project {
  /* background-color: darkcyan; */
  display: flex;
  justify-content: center;
  margin-top: 25px;
  margin-bottom: 15px;
  position: relative;
}

.icon-modal {
  width: 30px;
  margin-top: 8px;
  cursor: pointer;
}

.buttons-container {
  /* background-color: mediumpurple; */
  position: absolute;
  right: 0;
  bottom: 0;
  max-width: 30px;
}

/* NEW PROSPECT MODAL STYLE */
.prospect-modal-title {
  color: var(--secondary-color);
  text-transform: uppercase;
  font-weight: 550;
}

.select-user-container {
  display: flex;
}

.select-user-photo {
  background-color: tomato;
  width: 50px;
}

.select-user-text-container {
  margin-left: 15px;
}

.icons-prospect-container {
  display: flex;
  justify-content: right;
}

.icons-prospect-container img {
  margin-left: 15px;
  cursor: pointer;
}

.uk-tooltip {
  background-color: var(--bg-color);
  color: var(--text-gray-color);
}

.info-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.info-header h5 {
  color: #262626;
}

.info-header-dark h5 {
  color: #ffffff;
}

.delete-text {
  font-size: 12px;
  margin-bottom: 10px;
}

.info-container {
  margin-top: 20px;
}

.info-container h5 {
  color: var(--text-white-color);
  margin-bottom: 10px;
}

.oski-flex-end {
  justify-content: flex-end;
}

.oski-align-center {
  align-items: center;
}

.oski-pointer {
  cursor: pointer;
}

.oski-pointer:hover {
  filter: invert(43%) sepia(5%) saturate(100%) hue-rotate(149deg)
    brightness(97%) contrast(94%);
}

.leo-button-black {
  background-color: var(--bg-dark-gray);
  border-radius: 5px;
  padding: 11px 40px 10px 41px;
  min-width: 100px;
  color: var(--border-color);
  border: none;
}

.oski-card-container {
  background-color: black;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 5px;
  margin-top: 5px;
}

[data-theme='light'] .oski-card-container {
  background-color: #ffffff;
}

[data-theme='light'] .oski-card-text {
  color: var(--bg-dark-gray);
}

.oski-card-text {
  font-family: OpenSans;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 0.6px;
  color: white;
  cursor: pointer;
  transition: cubic-bezier();
  transition: ease-in-out 0.3s;
  margin: 7px 15px;
}

.oski-card-text:hover {
  color: var(--secondary-color);
  transition: ease-in-out 0.3s;
}

.oski-request-container {
  background-color: #262626;
  font-family: OpenSans;
  padding: 15px 25px;
  text-transform: uppercase;
  border-radius: 5px;
  cursor: pointer;
  margin-bottom: 20px;
}

[data-theme='light'] .oski-request-container {
  background-color: #ffffff;
}

.oski-request-container-owner {
  background-color: #444444;
  font-family: OpenSans;
  padding: 15px 25px;
  text-transform: uppercase;
  border-radius: 5px;
  cursor: pointer;
  margin-bottom: 20px;
}

[data-theme='light'] .oski-request-container-owner {
  background-color: #ffffff;
}

.oski-request-title {
  font-weight: bold;
  font-size: 16px;
  line-height: 26px;
  text-overflow: ellipsis;
  overflow: hidden;
  color: var(--secondary-color);
}

[data-theme='light'] .oski-request-title {
  color: var(--bg-dark-gray);
}

.oski-request-users {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  justify-content: left;
  align-items: center;
  gap: 5px;
  padding: 0px;
  margin-top: 5px;
}

.oski-request-users-text {
  color: #ffffff;
  font-size: 12px;
  text-transform: capitalize;
}

[data-theme='light'] .oski-request-users-text {
  color: var(--bg-dark-gray);
}

.oski-request-date {
  margin-top: 5px;
  font-family: OpenSans;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.42;
  letter-spacing: 0.36px;
  text-align: left;
  color: var(--card-color-yellow);
}

[data-theme='light'] .oski-request-date {
  color: var(--bg-dark-gray);
}

.oski-sol-container {
  width: 100%;
  margin-top: 20px;
}
