h1 {
  color: var(--text-gray-color);
}

.crm-container {
  width: 100%;
  height: auto;
  max-height: 600px;
  overflow: auto;
}

.leo-title-crm {
  color: var(--text-color) !important;
}
