.cj-wip-section {
  background-color: white;
  height: 100%;
  width: 100%;
  text-align: center;
}

.cj-icon-computer-wip {
  width: 46px;
  height: 30.7px;
  color: var(--primary-color);
}

.cj-wip-text-message {
  font-family: OpenSans;
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.35;
  letter-spacing: normal;

  max-width: 350px;
  margin: auto;
}
