.project-search-result-container {
  position: relative;
  cursor: pointer;
  margin-bottom: 48px;
}

.project-search-result-cover {
  height: 300px;
  position: relative;
}

.project-search-result-cover img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 5px;
}

.project-search-result-title {
  position: absolute;
  bottom: 25px;
  left: 0px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  word-wrap: break-word;
  width: 100%;
  color: var(--card-color-yellow);
  text-transform: uppercase;
  z-index: 101;

  font-family: OpenSans;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  padding-left: 10px;
  box-sizing: border-box;
  text-align: left;
}

.project-search-result-gradient {
  background-image: linear-gradient(
    to bottom,
    rgba(38, 38, 38, 0),
    var(--bg-dark-gray)
  );
  height: 127.5px;
  width: 100%;
  position: absolute;
  bottom: 0px;
  z-index: 100;
}

.project-search-result-img {
  object-fit: cover !important;
}

.project-search-result-tags {
  display: flex;
  position: absolute;
  bottom: 10px;
  left: 0px;
  width: 100%;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  color: var(--bg-color-alt);
  z-index: 101;
  box-sizing: border-box;

  font-family: OpenSans;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
  text-align: left;
}

.project-search-result-tag {
  margin-right: 7px;
}

.project-search-result-location {
  text-align: right;
  font-family: OpenSans;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;

  margin-top: 7px;
  color: var(--card-color-yellow);
}

.project-search-result-description {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  /* number of lines to show */
  text-align: right;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  color: var(--bg-color-alt);
}

.project-search-result-professionals {
  position: absolute;
  right: 0px;
  top: 20px;
  height: 50px;
  min-width: 100px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  background-color: var(--card-color-yellow);
  padding: 5px 10px 5px 8px;
  box-sizing: border-box;

  display: flex;
  align-items: center;
  gap: 5px;
}

.project-search-result-professionals .professional-name {
  font-family: OpenSans;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: #262626;

  word-break: keep-all;
  height: 2.66;
  max-width: 100px;
}
