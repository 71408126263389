.toggle-switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 25px;
}
.toggle-switch input[type='checkbox'] {
  display: none;
}
.toggle-switch .switch {
  position: absolute;
  cursor: pointer;
  background-color: var(--text-gray-color);
  border-radius: 25px;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transition: background-color 0.2s ease;
}

[data-theme='light'] .toggle-switch .switch {
  background-color: var(--background-button);
}
.toggle-switch .switch::before {
  position: absolute;
  content: '';
  left: 2px;
  top: 2px;
  width: 21px;
  height: 21px;
  background-color: #aaa;
  border-radius: 50%;
  transition: transform 0.3s ease;
}
.toggle-switch input[type='checkbox']:checked + .switch::before {
  transform: translateX(25px);
  background-color: #ff986f;
}
.toggle-switch input[type='checkbox']:checked + .switch {
  background-color: var(--primary-color);
}

.toggle-switch-text {
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.37;
  letter-spacing: 0.9px;
  text-align: left;
  color: var(--text-gray-color);
  margin-left: 5px;
  padding-top: 1px;
}

.cj-switch-error-span {
  color: var(--red-color) !important;
  opacity: 1 !important;
  margin-bottom: 30px;
}

.cj-switch-error-span span {
  margin-right: 3px;
}
