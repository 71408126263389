.diinco-horizontal-overflow-container {
  display: flex;
  flex-direction: row;
  row-gap: 5px;
  column-gap: 10px;
  width: 100%;
  height: 100%;
  margin: 20px 0px;
  padding-bottom: 10px;
}
