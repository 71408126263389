.register_container {
  height: 100%;
  display: flex;
  flex-direction: column;
  background-image: url('../assets/background-register.png');
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  /* background-size: cover; */
  background-size: calc(100% +20px);
}

.card {
  margin-top: 50px;
  width: 50%;
  margin-top: 100px;
  margin-bottom: 100px;
  align-self: center;
  padding: 40px 20px;
  border-radius: 5px;
  border: solid 1px var(--bg-color);
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  margin-bottom: 50px;
}
.card form {
  width: 80%;
}
.card .recaptcha-container {
  display: flex;
  margin-bottom: 20px;
  width: 100%;
  justify-content: center;
  align-items: center;
  align-content: center;
}
/* .register_container button {
	align-self: center;
	width: 100%;
} */
.register_container .uk-tab > .uk-active > a {
  color: var(--text-color) !important;
}

.register_container .uk-tab > a {
  color: var(--text-color) !important;
}

img.register_diinco {
  width: 60%;
  margin: 10px;
  object-fit: cover !important;
}

h1.register_diinco {
  font-family: OpenSans;
  font-size: 45px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.36;
  letter-spacing: 1.35px;
  text-align: left;
  color: var(--text-color);
  align-self: center;
}

div.diinco-terms-and-cons-text {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
div.diinco-terms-and-cons-text div.diinco-checkbox {
  height: 15px;
  width: 15px;
  margin: 0px 10px;
  border-radius: 3px;
  border-color: var(--primary-color);
  border-width: 2px;
  border-style: solid;
}
div.diinco-terms-and-cons-text div.diinco-checkbox.checked {
  background-color: var(--primary-color);
}

p.diinco-terms-and-cons-text {
  align-self: center;
  text-transform: uppercase;
  font-weight: 200;
  color: var(--text-color) !important;
}
p.diinco-terms-and-cons-text a {
  color: var(--primary-color) !important;
  font-weight: bolder;
}

.register-card {
  background-color: rgba(var(--bg-color-rgb2), 0.9);
}
