.dc-no-data-container {
  text-align: center;
  padding: 20px 0px;
}

.dc-no-data-text {
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.37;
  letter-spacing: 0.9px;
  color: var(--text-gray-color);
}
