.dc-bg-transparent {
  background: transparent;
  border-color: var(--text-gray-color);
  border: 1px solid;
}

.dc-width-xsmall {
  width: 1.5rem;
}

.dc-height-custom-4 {
  height: 4rem;
}

.custom-modal-buy {
  background-color: #262626;
  border-radius: 5px;
}
