.dc-tags-input-container {
  border: 2px solid var(--hover-color-alt);
  background-color: transparent;
  color: var(--text-gray-color);
  height: 220px;
  resize: none;
}

.dc-tags-input-header {
  margin: 5px 10px 0;
}

.dc-tags-input-header {
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.37;
  letter-spacing: 0.9px;
}

.dc-tags-input-button {
  background: transparent;
  border: none;
  color: var(--text-gray-color);
  font-size: 20px;
  cursor: pointer;
}
