.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

html {
  background-color: var(--bg-color);
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
#root {
  min-height: 100vh;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.diinco-security-filter-container {
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* cambio provisional */
  background-color: rgba(0, 0, 0, 1);
}
.diinco-security-filter-container img {
  margin-bottom: 50px;
  width: 40%;
}
.diinco-security-filter-container form button.diinco {
  width: 100%;
}
.diinco-security-filter-container form {
  width: 40%;
}

.disculpa-molestias {
  font-family: OpenSans;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.35;
  letter-spacing: 0.6px;
  text-align: center;
  color: #f9b74e;
  margin-top: 100px;
}
