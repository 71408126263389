/*@import url("../../../index.css");
*/

.dc-relative {
  position: relative;
}

.dc-img-cover-full {
  height: 100%;
  object-fit: cover;
}

.dc-overlay-bottom-left {
  position: absolute;
  bottom: 8px;
  left: 30px;
  font-size: 60px;
  font-weight: 200px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.37;
  letter-spacing: 12px;
  color: var(--text-gray-color);
}

.dc-uppercase {
  text-transform: uppercase;
}

/* === DESCRIPTION === */
.container-aboutus-description {
  margin-top: 3rem;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-auto-rows: minmax(auto, auto);
  gap: 10px;
}

.aboutus-description {
  grid-column: 3/11;
  grid-row: 1;
  color: var(--text-color);
  font-size: 25px;
  text-align: center;
  font-weight: normal;
  font-stretch: normal;
  letter-spacing: 0.75;
  line-height: 1.36;
}

.cj-aboutus-text-form {
  font-family: OpenSans;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.36;
  letter-spacing: 0.75px;
  text-align: center;
}
