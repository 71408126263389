img.diinco-project-banner {
  height: 400px;
  background-color: var(--bg-color);
  object-fit: cover;
}

.banner-relative {
  position: relative;
  width: 100%;
  margin-bottom: 20px;
  background-color: #535556;
}

.oski-project-title{
	width: 580px;
	position: absolute;
	background-color: rgba(0,0,0,0.7);
	border-bottom-left-radius: 5px;
	border-bottom-right-radius: 5px;
	bottom:0;
}

.oski-invitation-banner {
  width: 100%;
  height: 170px;
  object-fit: cover;
  border-radius: 5px;
}

.customModalPhase {
	background-color: rgba(var(--bg-color-rgb), 1);
	border-radius: 5px;
}
