.dc-margin-yellow {
  border: 1px solid var(--card-color-yellow);
  border-radius: 5px;
}

.dc-professional-card-img {
  margin-top: 20px;
  object-fit: cover;
  width: 100px;
  height: 100px;
}

.dc-professional-card-title {
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.35;
  letter-spacing: 0.5px;
  text-align: center;
  color: #fff;
  margin: 0px 15px 0px;
}

.dc-professional-card-text {
  text-transform: uppercase;
}

.dc-professional-card-city {
  margin-top: 14px !important;
  margin-bottom: 10px !important;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.35;
  letter-spacing: 0.5px;
  text-align: center;
  color: var(--card-color-yellow);
  margin: 0px 15px 0px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
