.dc-notification-item-container {
  border-radius: 5px;
  background-color: var(--hover-color-alt);

  display: flex;
  flex-direction: column;
  row-gap: 3px;
}

[data-theme="light"] .dc-notification-item-container {
  background-color: white;
  border: 1px solid var(--hover-color-alt);
  color: var(--hover-color-alt);
}

[data-theme="light"] .dc-notification-description {
  color: var(--hover-color-alt);
}
[data-theme="light"] .dc-notification-sublink {
  color: var(--hover-color-alt);
}

.dc-notification-item-container:hover {
  cursor: pointer;
}

.dc-notification-sublink {
  font-family: OpenSans;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 0.45px;
  color: #fff;
}

.dc-notification-description {
  display: flex;
  align-items: center;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis !important;
  font-family: OpenSans;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 0.45px;
  color: #fff;

  margin: 11px 17px 0px !important;
}

.dc-aqsa {
  margin: 3px 17px !important;
  text-align: right;
  font-size: 10px;
}