.uk-navbar-dropdown.logincard {
  min-width: 505px;
  min-height: 560px;
  max-height: 560px;
  max-width: 505px;
  width: 505px;
  height: 546px;
  padding: 51px 54px 122px 54.5px;
  object-fit: contain;
  border-radius: 5px;
  background-color: rgba(var(--bg-color-rgb), 0.9);
  opacity: 1 !important;
}

[data-theme='light'] .cj-dropdown-logincard {
  min-width: 505px;
  min-height: 560px;
  max-height: 560px;
  max-width: 505px;
  width: 505px;
  height: 546px;
  padding: 51px 54px 122px 54.5px;
  object-fit: contain;
  border-radius: 5px;
  background-color: white !important;
  z-index: 1020;
  opacity: 1 !important;
}

[data-theme='dark'] .cj-dropdown-logincard {
  min-width: 505px;
  min-height: 560px;
  max-height: 560px;
  max-width: 505px;
  width: 505px;
  height: 546px;
  padding: 51px 54px 122px 54.5px;
  object-fit: contain;
  border-radius: 5px;
  background-color: rgba(0, 0, 0, 0.9);
  z-index: 1020;
  opacity: 1 !important;
}

.logincard-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.logincard-container img {
  width: 100%;
  margin-bottom: 50px;
  object-fit: contain;
  align-self: center;
}

.logincard-container span.forgot-password {
  color: #fbb857;
  align-self: center;
  margin: 10px;
  cursor: pointer;
}

.logincard-container span.register-now {
  color: #f36f39;
  align-self: center;
  margin: 10px;
  cursor: pointer;
}

.diinco-centered {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-content: center;
}

.dc-error-container {
  height: 100vh !important;
  text-align: center;
}

.dc-error-img {
  margin-bottom: 40px;
}

.dc-error-text {
  color: var(--text-gray-color);
  font-size: 64px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.22;
  letter-spacing: 2.07px;
}
