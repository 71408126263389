.diinco-user-search-option-container {
  position: relative;
  display: flex;
  flex-direction: row;
  column-gap: 10px;
  justify-content: flex-start;
  align-items: center;
  padding: 4px 2px;
}
.diinco-user-search-option-image {
  height: 50px !important;
  width: 50px !important;
  aspect-ratio: 1 !important;
  border-radius: 100%;
  object-fit: cover;
}

.diinco-user-search-option-data {
  margin-left: 30px;
  margin: 0px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}
.diinco-user-search-option-data p {
  font-weight: bold;
  margin: 0;
}
.diinco-user-search-option-data span {
  margin: 0;
  font-size: 0.8rem;
}
