.oski-title {
  font-family: OpenSans;
  font-size: 30px;
  font-weight: bold;
}

.oski-subtitle {
  color: var(--secondary-color);
  font-size: 20px;
  line-height: 27px;
  letter-spacing: 0.6px;
  font-family: OpenSans;
  font-weight: bold;
}

.oski-question {
  font-family: OpenSans;
  font-size: 15px;
  line-height: 20px;
  letter-spacing: 0.45px;
  text-align: justify;
  text-justify: inter-word;
}

.oski-answer {
  font-family: OpenSans;
  font-size: 15px;
  line-height: 20px;
  letter-spacing: 0.45px;
  text-align: justify;
  text-justify: inter-word;
}
