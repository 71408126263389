.diinco-calendar {
  width: 100%;
  height: 600px;
  margin-top: 200px;
}

.calendarModal {
  min-width: 300px;
  padding: 30px;
  background-color: rgba(var(--bg-color-rgb2), 0.9);
  border-radius: 5px;
}

[data-theme='light'] .calendarModal {
  min-width: 300px;
  padding: 30px;
  background-color: rgba(var(--bg-color-rgb2), 1);
  border-radius: 5px;
}
