.diinco-custom-dropzone-div {
  display: flex;
  width: 100%;
  height: 100%;
  position: relative;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.diinco-custom-dropzone-div img {
  height: 20%;
  width: auto;
  align-self: center;
}

.cj-error-message-DZCI {
  font-size: 12px;
  color: var(--red-color);
  opacity: 1;
  font-weight: 400;
  text-align: left;
}

.cj-error-message-DZCI span {
  margin-right: 3px;
}
